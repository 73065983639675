import React from 'react';
import { node, shape } from 'prop-types';

import { Header } from 'components/sections/Headers/Header';
import { Footer } from 'components/sections/Footer';
import { SEO } from 'components/elements/SEO';

export const Layout = ({ location, children, headerProps, footerProps }) => (
  <>
    <SEO pathname={location.pathname} />
    <Header {...{ ...headerProps, ...{ location } }} />
    <main>{children}</main>
    <Footer {...footerProps} />
  </>
);

Layout.propTypes = {
  location: shape(),
  children: node,
  headerProps: shape(),
  footerProps: shape(),
};
