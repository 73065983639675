import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const SEO = ({ pathname, icon, spt }) => {
  const {
    site: {
      siteMetadata: { author, description, language, siteUrl, theme, title },
    },
  } = useStaticQuery(graphql`
    query Metadata {
      site {
        siteMetadata {
          author
          description
          language
          siteUrl
          theme
          title
        }
      }
    }
  `);

  const scriptFontFace = `
    if ('fonts' in document) {
      const dmSansRegular = new FontFace(
          'DM Sans',
          "url(/fonts/DMSans-Regular.woff2) format('woff2'), url(/fonts/DMSans-Regular.woff) format('woff')",
          { weight: '500' }
      );
      const dmSansMedium = new FontFace(
          'DM Sans',
          "url(/fonts/DMSans-Medium.woff2) format('woff2'), url(/fonts/DMSans-Medium.woff) format('woff')",
          { weight: '600' }
      );
      const dmSansBold = new FontFace(
          'DM Sans',
          "url(/fonts/DMSans-Bold.woff2) format('woff2'), url(/fonts/DMSans-Bold.woff) format('woff')",
          { weight: '700' }
      );
      Promise.all([
          dmSansRegular.load(),
          dmSansMedium.load(),
          dmSansBold.load()
      ]).then(fonts => {
          for (const font of fonts) {
              document.fonts.add(font);
          }
      });
    }
  `;

  return (
    <Helmet defaultTitle={title}>
      <html lang={language} />
      <meta
        name="viewport"
        content="width=device-width,minimum-scale=1.0,initial-scale=1.0,maximum-scale=5.0,viewport-fit=cover"
      />
      <link rel="canonical" href={`${siteUrl}${pathname}`} />
      <meta property="author" content={author} />
      <meta name="description" content={description} />
      <meta name="image" content={`${siteUrl}${icon}`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}${icon}`} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta
        property="og:type"
        content={pathname.match(/posts\/.*\//) ? 'article' : 'website'}
      />
      <meta property="og:url" content={`${siteUrl}${pathname}`} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="apple-mobile-web-app-title" content={title} />
      <link rel="mask-icon" color={theme} href={`${siteUrl}${spt}`} />

      <script type="text/javascript">{scriptFontFace}</script>
    </Helmet>
  );
};

SEO.propTypes = {
  pathname: PropTypes.string,
  icon: PropTypes.string,
  spt: PropTypes.string,
};
