import React, { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { ButtonInputFocus } from '../ButtonInputFocus';

import styles from './styles.module.css';

export const EmailInput = forwardRef(
  ({ name, value, refNextInputToFocusOnEnterKeyPressed, ...rest }, ref) => {
    const defaultValue = value;

    const FORM = useFormContext();

    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
      FORM.register(
        { name, type: 'custom' },
        {
          required: 'Please provide an email address',
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Please provide valid email address',
          },
        }
      );

      FORM.setValue(name, value);
    }, [FORM.register]);

    const onBlur = async () => {
      const isValid = await FORM.triggerValidation({ name });

      setIsInvalid(!isValid);
    };

    const onFocus = event => {
      event.target.select();

      FORM.setFieldsTouched({ ...FORM.fieldsTouched, [name]: true });
    };

    const onChange = event => {
      FORM.setValue(name, event.target.value);
    };

    const onKeyPress = event => {
      if (event.key === 'Enter') {
        // eslint-disable-next-line no-unused-expressions
        refNextInputToFocusOnEnterKeyPressed?.current?.focus();
      }
    };

    return (
      <div className={styles.wrapper}>
        <input
          id={name}
          type="email"
          placeholder="Email Address"
          className={styles.input}
          invalid={isInvalid.toString()}
          {...{
            ref,
            name,
            onBlur,
            onFocus,
            onChange,
            onKeyPress,
            defaultValue,
            ...rest,
          }}
        />

        <ButtonInputFocus refInput={ref} />

        <div className={styles.errorMessage}>
          {FORM.errors?.[name]?.message}
        </div>
      </div>
    );
  }
);

EmailInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  refNextInputToFocusOnEnterKeyPressed: PropTypes.shape({
    current: PropTypes.any,
  }),
};

EmailInput.displayName = 'EmailInput';
