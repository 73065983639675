import React, { useEffect, useState, useRef } from 'react';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';

import { getRatesParams } from 'base/store/selectors';

import { useClientData } from 'base/utils/useClientData';

import { Button } from 'components/elements/Buttons/Button';
import { Link } from 'components/elements/Links/Link';
import { OverlayMobileFormFilterOffers } from './OverlayMobileFormFilterOffers';
import { FilterSVG, HashSVG, LogoSVG, PhoneSVG } from 'base/assets/icons';

import styles from './styles.module.css';

const useFixedHeaderPlaceholder = () => {
  const refHeader = useRef();
  const refPlaceholder = useRef();

  useEffect(() => {
    updatePlaceholderHeight();
  }, []);

  useEffect(() => {
    updatePlaceholderHeight();
  }, [refHeader, refPlaceholder]);

  useEffect(() => {
    window.addEventListener('resize', updatePlaceholderHeight);

    return () => window.removeEventListener('resize', updatePlaceholderHeight);
  }, [refHeader, refPlaceholder]);

  const updatePlaceholderHeight = () => {
    if (refPlaceholder?.current?.style) {
      const isViewportDesktop = window.innerWidth > 992;

      if (isViewportDesktop) {
        refPlaceholder.current.style.height = `0px`;
      } else {
        const headerHeight =
          refHeader?.current?.getBoundingClientRect()?.height || 80;

        refPlaceholder.current.style.height = `${headerHeight}px`;
      }
    }
  };

  return { refHeader, refPlaceholder };
};

export function HeaderWithFilters({ data, style }) {
  const { refHeader, refPlaceholder } = useFixedHeaderPlaceholder();

  const ratesParams = useSelector(getRatesParams);
  const { phoneNumber, preapprovalNumber, hrefTel } = useClientData();

  const [isOverlayOpened, setIsOverlayOpened] = useState(false);

  const openOverlay = () => {
    setIsOverlayOpened(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpened(false);
  };

  return (
    <>
      <section ref={refHeader} className={`section-wide ${styles.fixedHeader}`}>
        <header className={styles.header}>
          <Link to="/">
            <img
              data-testid="header-img"
              src={data?.logo?.filename || LogoSVG}
              alt="youarepreapproved-logo"
              loading="lazy"
              decoding="async"
            />
          </Link>

          {ratesParams && (
            <div className={styles.contact} style={style}>
              <Link to={hrefTel} data-testid="header-phone" variant="with-icon">
                {phoneNumber}
                <Button variant="xs">
                  <img
                    src={PhoneSVG}
                    alt="phone-icon"
                    loading="lazy"
                    decoding="async"
                  />
                </Button>
              </Link>

              <Link
                data-testid="header-preapproval"
                to="#"
                variant="with-icon"
                disabled
              >
                PreApproval # {preapprovalNumber}
                <Button disabled variant="xs">
                  <img
                    src={HashSVG}
                    alt="hash-icon"
                    loading="lazy"
                    decoding="async"
                  />
                </Button>
              </Link>
            </div>
          )}
        </header>
        {data.filter_button && (
          <div
            className={styles.headerButtons}
            opened={isOverlayOpened.toString()}
          >
            <Link
              data-testid="filters-mobile"
              variant="flex"
              role="button"
              onClick={openOverlay}
            >
              <div>Filter Offers</div>
              <img
                src={FilterSVG}
                alt="filter-icon"
                loading="lazy"
                decoding="async"
              />
            </Link>
          </div>
        )}
        {preapprovalNumber && (
          <div style={style}>
            <OverlayMobileFormFilterOffers
              {...{ isOpened: isOverlayOpened, closeOverlay, data }}
            />
          </div>
        )}
      </section>

      <div ref={refPlaceholder} />
    </>
  );
}

HeaderWithFilters.propTypes = {
  data: object,
  style: object,
};
