import React from 'react';
import SbEditable from 'storyblok-react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getLoading } from 'base/store/selectors';

import { RichText } from 'components/elements/RichText';
import { Spinner } from 'components/elements/Spinner';

import styles from './styles.module.css';

export const HeroOffers = ({ data }) => {
  const loading = useSelector(getLoading);

  if (loading.type === 'lookup' && loading.isLoading) {
    return <Spinner label="Loading..." />;
  }

  return (
    <section id="results-hero" className="section-wide">
      <SbEditable content={data}>
        <div>
          {data?.logo?.filename && (
            <div className={styles.logo}>
              <img
                src={data.logo.filename}
                alt="lender-logo"
                loading="lazy"
                decoding="async"
                height="100"
              />
            </div>
          )}

          <RichText className={styles.hero} data={data.body} />
        </div>
      </SbEditable>
    </section>
  );
};

HeroOffers.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.shape({
      filename: PropTypes.string,
    }),
    body: PropTypes.any,
  }),
};
