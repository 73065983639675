import React from 'react';
import { RichText } from 'components/elements/RichText';

import { useStoryblokComponent } from 'base/hooks/useStoryblokComponent';

export const Calendly = props => {
  const { StoryblokComponent, data } = useStoryblokComponent(props);

  if (!data.Enabled) return null;

  return (
    <StoryblokComponent>
      <RichText data={data.URL} isCalendly={true} />
    </StoryblokComponent>
  );
};
