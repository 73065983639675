import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

import { ArrowSVGElement } from 'base/assets/icons';

import styles from './styles.js';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ArrowSVGElement height="25" width="25" stroke="#F7941D" />
  </components.DropdownIndicator>
);

export const SelectDropdownInput = ({
  name,
  value,
  register,
  setValue,
  required = false,
  options,
}) => {
  const defaultValue = options.find(option => option.value === value);

  const [selectedOption, setSelectedOption] = useState(defaultValue);

  useEffect(() => {
    register({ name, type: 'custom' }, { required });
    onChange(defaultValue);
  }, [register]);

  const onChange = selectedOption => {
    setSelectedOption(selectedOption);
    setValue(name, selectedOption?.value);
  };

  return (
    <Select
      components={{ DropdownIndicator }}
      defaultValue={defaultValue}
      value={selectedOption}
      onChange={onChange}
      options={options}
      styles={styles}
    />
  );
};

SelectDropdownInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  register: PropTypes.func,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};
