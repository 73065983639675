import React, { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { CheckmarkSVGElement } from 'base/assets/icons';

import styles from './styles.module.css';

export const CheckboxInput = forwardRef(
  (
    {
      name,
      checked = false,
      refNextInputToFocusOnEnterKeyPressed,
      linkLegal,
      openModalTerms,
      ...rest
    },
    ref
  ) => {
    const FORM = useFormContext();

    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
      FORM.register(
        { name, type: 'custom' },
        { required: 'Please accept terms and conditions' }
      );

      FORM.setValue(name, checked);
    }, [FORM.register]);

    const onBlur = async () => {
      const isValid = await FORM.triggerValidation({ name });

      setIsInvalid(!isValid);
    };

    const onChange = event => {
      FORM.setValue(name, event.target.checked);

      onBlur();

      FORM.setFieldsTouched({ ...FORM.fieldsTouched, [name]: true });
    };

    const onKeyPress = event => {
      if (event.key === 'Enter') {
        // eslint-disable-next-line no-unused-expressions
        refNextInputToFocusOnEnterKeyPressed?.current?.focus();
      }
    };

    const onClickLinkTerms = e => {
      e.preventDefault();
      openModalTerms();
    };

    // eslint-disable-next-line react/prop-types
    const TermsAndConditionsLink = ({ children }) =>
      linkLegal ? (
        <a href={linkLegal} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a onClick={onClickLinkTerms}>{children}</a>
      );

    return (
      <div>
        <label className={styles.wrapper}>
          <input
            id={name}
            type="checkbox"
            className={styles.input}
            invalid={isInvalid.toString()}
            {...{
              ref,
              name,
              onBlur,
              onChange,
              onKeyPress,
              ...rest,
            }}
          />
          <CheckmarkSVGElement className={styles.checkmark} />
          <span>
            I accept the{' '}
            <TermsAndConditionsLink>
              terms and conditions
            </TermsAndConditionsLink>
          </span>
        </label>

        <div invalid={isInvalid.toString()} className={styles.errorMessage}>
          {isInvalid && 'Please accept terms and conditions'}
        </div>
      </div>
    );
  }
);

CheckboxInput.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  refNextInputToFocusOnEnterKeyPressed: PropTypes.shape({
    current: PropTypes.any,
  }),
  linkLegal: PropTypes.string,
  openModalTerms: PropTypes.func,
};

CheckboxInput.displayName = 'CheckboxInput';
