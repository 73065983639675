import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/elements/Links/Link';
import { FormLockMyRate } from 'components/sections/Forms/FormLockMyRate';
import { MobileOverlay } from 'components/elements/Modals/MobileOverlay';
import { RichText } from 'components/elements/RichText';

import { CheckmarkSVG } from 'base/assets/icons';

import styles from './styles.module.css';

const FORM_NAME = 'lock-my-rate-overlay';

export const ModalMobileFormLockMyRate = ({
  data,
  isOpened,
  closeOverlay,
  openModalTerms,
}) => {
  const headerTop = data?.title_top;

  return (
    <MobileOverlay
      overlayHeight={90}
      label={
        <Link role="button" onClick={closeOverlay}>
          <>
            {typeof headerTop === 'object' && <RichText data={headerTop} />}
            {typeof headerTop === 'string' && headerTop}
          </>
        </Link>
      }
      {...{ isOpened, closeOverlay }}
    >
      <div
        style={{
          '--color-step-active':
            data?.color_step_active?.color || 'var(--color-primary)',
        }}
      >
        <div className={styles.steps}>
          {(data?.steps || []).map(step => (
            <div
              key={step._uid}
              className={step?.is_active && styles.stepAccount}
            >
              <p>{step?.title_short}</p>
              <div className={styles.stepIcon}>
                <img
                  src={step?.icon?.filename || CheckmarkSVG}
                  alt="checkmark-icon"
                  loading="lazy"
                  decoding="async"
                />
              </div>
            </div>
          ))}
        </div>

        {(data?.steps || [])
          .filter(step => step.is_active)
          .map(step => (
            <div key={step._uid} className={styles.boxProfile}>
              <h4>{step?.title}</h4>
              <p>{step?.subtitle}</p>
            </div>
          ))}
      </div>

      <div className={styles.form}>
        {data?.logo?.filename && (
          <img
            src={data?.logo?.filename}
            alt="lender-logo"
            height="100"
            loading="lazy"
            decoding="async"
          />
        )}
        <FormLockMyRate
          formName={FORM_NAME}
          data={data}
          openModalTerms={openModalTerms}
        />
      </div>

      <h3 className={styles.headerBottom}>{data?.title_bottom}</h3>
      <p className={styles.contentBottom}>
        {data?.disclaimer_below_header_bottom}
      </p>
      <div style={{ paddingBottom: 80 }} />
    </MobileOverlay>
  );
};

ModalMobileFormLockMyRate.propTypes = {
  data: PropTypes.shape(),
  isOpened: PropTypes.bool,
  closeOverlay: PropTypes.func,
  openModalTerms: PropTypes.func,
};
