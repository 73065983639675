import React from 'react';
import PropTypes from 'prop-types';

import { FormLockMyRate } from 'components/sections/Forms/FormLockMyRate';
import { Modal } from 'components/elements/Modals/Modal';

import { CheckmarkSVG } from 'base/assets/icons';

import styles from './styles.module.css';

const FORM_NAME = 'lock-my-rate-modal';

export const ModalDesktopFormLockMyRate = ({
  data,
  isOpened,
  closeModal,
  openModalTerms,
}) => {
  if (!isOpened) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Modal
        close={closeModal}
        headerTop={data?.title_top}
        headerBottom={data?.title_bottom}
        contentBottom={data?.disclaimer_below_header_bottom}
      >
        <div className={styles.content}>
          <div
            className={styles.steps}
            style={{
              '--color-step-active':
                data?.color_step_active?.color || 'var(--color-primary)',
            }}
          >
            {(data?.steps || []).map(step => (
              <div
                key={step._uid}
                className={step?.is_active && styles.stepAccount}
              >
                <div className={styles.stepIcon}>
                  <img
                    src={step?.icon?.filename || CheckmarkSVG}
                    alt="checkmark-icon"
                    loading="lazy"
                    decoding="async"
                  />
                </div>
                <div>
                  <h4>{step?.title}</h4>
                  <p>{step?.subtitle}</p>
                </div>
              </div>
            ))}
          </div>

          <div className={styles.form}>
            {data?.logo?.filename && (
              <img
                src={data?.logo?.filename}
                alt="lender-logo"
                height="75"
                loading="lazy"
                decoding="async"
              />
            )}
            <FormLockMyRate
              formName={FORM_NAME}
              data={data}
              openModalTerms={openModalTerms}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

ModalDesktopFormLockMyRate.propTypes = {
  data: PropTypes.shape(),
  isOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  openModalTerms: PropTypes.func,
};
