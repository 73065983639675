import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUpdate } from 'base/store/selectors';

export const useRequestLastUpdateTime = () => {
  const update = useSelector(getUpdate);

  const [relative, setRelative] = useState('[time]');
  const [iso, setIso] = useState('');

  useEffect(() => {
    if (update?.iso) {
      setIso(update.iso);
    }

    if (update?.relative) {
      setRelative(update.relative);
    }
  }, [update]);

  return `<span><time data-testid="current-date" dateTime=${iso}>${relative}</time></span>`;
};
