import React from 'react';
import SbEditable from 'storyblok-react';
import PropTypes from 'prop-types';

import { removeEmpty } from 'base/utils/removeEmpty';

export const ButtonWithStoryblokStyles = ({ data = {}, children }) => {
  const {
    color_font,
    hover_color_font,

    color_background,
    hover_color_background,
    override_background,
    override_hover_background,

    color_shadow,
    hover_color_shadow,
    override_shadow,
    override_hover_shadow,
  } = data;

  const cssVariables = {
    '--color-font-button': color_font?.color,
    '--color-font-button-hover': hover_color_font?.color,

    '--background-primary': override_background || color_background?.color,
    '--background-primary-active':
      override_hover_background || hover_color_background?.color,

    '--shadow-primary':
      override_shadow ||
      (color_shadow?.color && `0px 10px 35px ${color_shadow?.color}`),
    '--shadow-primary-active':
      override_hover_shadow ||
      (hover_color_shadow?.color &&
        `0px 10px 35px ${hover_color_shadow?.color}`),
  };

  const style = removeEmpty(cssVariables);

  return (
    <SbEditable content={data}>
      <div style={style}>{children}</div>
    </SbEditable>
  );
};

ButtonWithStoryblokStyles.propTypes = {
  data: PropTypes.shape(),
  children: PropTypes.node,
};
