import { useDispatch, useSelector } from 'react-redux';

import { getRequestId } from 'base/store/selectors';
import { validateZipQuery } from 'base/store/actions';

export const useZipRequest = () => {
  const dispatch = useDispatch();

  const requestId = useSelector(getRequestId);

  const submitRequest = (resnum, zip) => {
    if (requestId) {
      dispatch(
        validateZipQuery({
          requestId,
          zip: zip,
          resNum: resnum,
        })
      );
    }
  };

  return { submitRequest };
};
