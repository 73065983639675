import React from 'react';
import PropTypes from 'prop-types';

import { useStoryblokComponent } from 'base/hooks/useStoryblokComponent';

import { RichText } from 'components/elements/RichText';

import styles from './styles.module.css';

const resolveClassName = spacing => {
  if (spacing === 'small') return styles['paddingSmall'];
  if (spacing === 'medium') return styles['paddingMedium'];
  if (spacing === 'large') return styles['paddingLarge'];

  return '';
};

export const RichTextSection = props => {
  const { StoryblokComponent, data } = useStoryblokComponent(props);

  return (
    <StoryblokComponent>
      <RichText
        data={data.content}
        className={resolveClassName(data.spacing)}
      />
    </StoryblokComponent>
  );
};

RichTextSection.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.any,
  }),
};
