import React from 'react';
import SbEditable from 'storyblok-react';
import { shape, string } from 'prop-types';

import { FormPreloadFromNames } from 'components/sections/Forms/FormPreloadOffers/FormPreloadFromNames';
import { FormPreloadFromPreapproval } from 'components/sections/Forms/FormPreloadOffers/FormPreloadFromPreapproval';
import { FormPreloadFromPreapproval10 } from 'components/sections/Forms/FormPreloadOffers/FormPreloadFromPreapproval10';
import { FormPreloadFromURLButtons } from 'components/sections/Forms/FormPreloadOffers/FormPreloadFromURLButtons';

import { BlobSVG } from 'base/assets/decorations';

import styles from './styles.module.css';

const FormList = {
  names: FormPreloadFromNames,
  preapproval: FormPreloadFromPreapproval,
  'preapproval-8': FormPreloadFromPreapproval,
  'preapproval-10': FormPreloadFromPreapproval10,
  'url-buttons': FormPreloadFromURLButtons,
};

export function ColumnForm({ data, creative, dataset, source, ...rest }) {
  return (
    <SbEditable content={data}>
      <div className={styles.form}>
        {React.createElement(FormList[data?.version], {
          data,
          creative,
          dataset,
          source,
          version: data.version,
          ...rest,
        })}
        <div className={styles.decorations}>
          <img src={BlobSVG} alt="oval" loading="lazy" decoding="async" />
          <img src={BlobSVG} alt="oval" loading="lazy" decoding="async" />
        </div>
      </div>
    </SbEditable>
  );
}

ColumnForm.propTypes = {
  data: shape(),
  creative: string,
  dataset: string,
  source: string,
};
