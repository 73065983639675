const font = {
  fontFamily: 'var(--font-family-body)',
  fontSize: 'var(--font-size-sm)',
  fontWeight: 'var(--font-weight-normal)',
  color: 'var(--color-font-heading)',
};

export default {
  control: provided => ({
    ...provided,
    minHeight: 'unset',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',

    '&:hover': { border: 'none', boxShadow: 'none' },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    transform: `rotateX(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    transition: 'transform 0.25s',

    '&:hover': {
      transform: `rotateX(${
        state.selectProps.menuIsOpen ? 180 : 0
      }deg) scale(1.2)`,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 2,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  noOptionsMessage: provided => ({
    ...provided,
    ...font,
  }),
  option: (provided, state) => ({
    ...provided,
    ...font,

    color: state.isSelected
      ? `var(--color-white)`
      : state.isFocused
      ? `var(--color-white)`
      : `var(--color-font-heading)`,

    background: state.isSelected
      ? state.isFocused
        ? `var(--background-hover)`
        : `var(--background-active)`
      : state.isFocused
      ? `var(--background-hover)`
      : `var(--color-white)`,

    '&:active': {
      background: 'var(--background-active)',
    },
  }),
  placeholder: provided => ({
    ...provided,
    ...font,
    color: 'var(--color-placeholder)',
  }),
  singleValue: provided => ({
    ...provided,
    ...font,
    overflow: 'visible',
  }),
  valueContainer: provided => ({
    ...provided,
    ...font,
    padding: 0,
  }),
};
