import React from 'react';
import PropTypes from 'prop-types';

import { useStoryblokComponent } from 'base/hooks/useStoryblokComponent';
import { RichText } from 'components/elements/RichText';

export const Box = props => {
  const { StoryblokComponent, data } = useStoryblokComponent(props);
  const topSpacing = `${data.top_spacing ?? 32}px`;
  const bottomSpacing = `${data.bottom_spacing ?? 32}px`;
  const styles = {
    backgroundColor: data.background?.color ?? '',
    color: data.text_color?.color ?? '',
    marginTop: topSpacing,
    marginBottom: bottomSpacing,
    paddingTop: topSpacing,
    paddingBottom: bottomSpacing,
  };

  return (
    <StoryblokComponent>
      <section className="section-wide">
        <RichText data={data.content} className="box" style={styles} />
      </section>
    </StoryblokComponent>
  );
};

Box.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.any,
    background: PropTypes.shape({
      plugin: PropTypes.string,
      color: PropTypes.string,
      _uid: PropTypes.string,
    }),
    text_color: PropTypes.shape({
      plugin: PropTypes.string,
      color: PropTypes.string,
      _uid: PropTypes.string,
    }),
    top_spacing: PropTypes.number,
    bottom_spacing: PropTypes.number,
  }),
};
