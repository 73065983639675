import React from 'react';
import { node, shape } from 'prop-types';

import { HeaderWithFilters } from 'components/sections/Headers/HeaderWithFilters';
import { Footer } from 'components/sections/Footer';
import { SEO } from 'components/elements/SEO';

export function LayoutWithFilters({
  location,
  children,
  headerProps,
  footerProps,
  style,
}) {
  return (
    <>
      <SEO pathname={location.pathname} />
      <HeaderWithFilters {...{ ...headerProps, style }} />
      <main>{children}</main>
      <Footer {...footerProps} />
    </>
  );
}

LayoutWithFilters.propTypes = {
  location: shape(),
  children: node,
  headerProps: shape(),
  footerProps: shape(),
  style: shape(),
};
