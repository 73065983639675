import React from 'react';
import SbEditable from 'storyblok-react';
import { number } from 'prop-types';

import { Button } from 'components/elements/Buttons/Button';
import { RichText } from 'components/elements/RichText';

import styles from './styles.module.css';

export function Step({ stepNr, ...data }) {
  const handleScrollToMainForm = () => {
    window.scrollTo(0, 0);
  };

  const handleFindMyRates = () => {
    const button = window.document.getElementById(
      'button-submit-form-get-your-best-rate'
    );
    button.click();
  };

  const handleCallUs = () => {
    const button = window.document.getElementById(
      'button-call-form-get-your-best-rate'
    );
    button.click();
  };

  return (
    <>
      <input
        id={data?._uid}
        name="how-it-works-radio-input"
        type="radio"
        className={styles.input}
        defaultChecked={stepNr === 0}
      />
      <SbEditable content={data}>
        <div className={styles.step}>
          <Button variant="square" theme="secondary" data-testid="step-button">
            <label htmlFor={data?._uid} />
          </Button>

          <div className={styles.content} data-testid="step-content">
            <label htmlFor={data?._uid}>
              <h4
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: data?.title }}
              />
            </label>
            <div className={styles.description}>
              <RichText
                data={data.description}
                customFunctions={{
                  scrollToMainForm: handleScrollToMainForm,
                  findMyRates: handleFindMyRates,
                  callUs: handleCallUs,
                }}
              />
            </div>
          </div>
        </div>
      </SbEditable>
    </>
  );
}

Step.propTypes = {
  stepNr: number,
};
