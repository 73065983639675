import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export const Button = forwardRef(
  ({ theme = 'primary', variant, children, ...rest }, ref) => (
    <button {...{ ref, className: styles.button, theme, variant, ...rest }}>
      {children}
    </button>
  )
);

Button.propTypes = {
  theme: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
};

Button.displayName = 'Button';
