import React from 'react';
import SbEditable from 'storyblok-react';
import PropTypes from 'prop-types';

import { getSectionId } from 'base/utils/getSectionId';

import { LinkButton } from 'components/elements/Links/LinkButton';

import { Card } from './Card';
import { SeparatorWideSVG } from 'base/assets/decorations';

import styles from './styles.module.css';

export const Showcase = ({ data }) => (
  <SbEditable content={data}>
    <section id={getSectionId(data)} className="section-wide">
      <div className={styles.wrapper}>
        <h2 dangerouslySetInnerHTML={{ __html: data.title }} />

        <div className={styles.cards}>
          {(data?.cards || []).map(card => (
            <Card key={card._uid} data={card} />
          ))}
        </div>
        <div className={styles.cta}>
          <img
            src={SeparatorWideSVG}
            alt="separator-line"
            loading="lazy"
            decoding="async"
          />
          <LinkButton to="#contact">{data?.cta_button}</LinkButton>
        </div>
      </div>
    </section>
  </SbEditable>
);

Showcase.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    cards: PropTypes.array,
    cta_button: PropTypes.any,
  }),
};
