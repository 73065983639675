import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { Button } from 'components/elements/Buttons/Button';
import { Input } from '../Input';

import styles from './styles.module.css';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export const FormContact = () => {
  const [formState, setFormState] = useState({
    first_name: '',
    email: '',
    message: '',
  });

  const handleChange = e =>
    setFormState(state => ({
      ...state,
      [e.target.name]: e.target.value,
    }));

  const handleSubmit = e => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...formState }),
    })
      .then(() => navigate('/success'))
      .catch(error => alert(error));
  };

  return (
    <div className={styles.wrapper}>
      <form data-testid="contact-form" onSubmit={handleSubmit}>
        <div className={styles.form}>
          <Input
            id="contact"
            name="first_name"
            data-testid="first-name-email"
            placeholder="First name"
            onChange={handleChange}
            value={formState.first_name}
          />
          <Input
            name="last_name"
            data-testid="last-name-email"
            placeholder="Last Name"
          />

          <div className={styles.column}>
            <Input
              required
              name="email"
              type="email"
              placeholder="Email address"
              onChange={handleChange}
              value={formState.email}
              data-testid="contact-email"
              errorMessage="Please provide a valid email address"
            />
          </div>

          <div className={styles.column}>
            <Input
              Component="textarea"
              required
              name="message"
              data-testid="contact-message"
              placeholder="Message"
              rows="5"
              minLength="10"
              onChange={handleChange}
              value={formState.message}
              errorMessage="Please provide a message (min 10 characters)"
            />
          </div>

          <div className={styles.column}>
            <Button type="submit" data-testid="contact-button">
              Send message
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
