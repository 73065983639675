import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  getApplication,
  getError,
  getLoading,
  getSuccess,
} from 'base/store/selectors';
import { setSuccess } from 'base/store/actions';

import { useApplicationRequest } from 'base/utils/API/requests/useApplicationRequest';

import { ButtonWithStoryblokStyles } from 'components/elements//ButtonWithStoryblokStyles';
import { Link } from 'components/elements/Links/Link';
import { ButtonSubmit } from 'components/elements/Buttons/ButtonSubmit';

import { CheckboxInput } from './Inputs/CheckboxInput';
import { EmailInput } from './Inputs/EmailInput';
import { PasswordInput } from './Inputs/PasswordInput';
import { PhoneNumberInput } from './Inputs/PhoneNumberInput';

import styles from './styles.module.css';

export const FormLockMyRate = ({ data, formName, openModalTerms }) => {
  const dispatch = useDispatch();

  const application = useSelector(getApplication);
  const error = useSelector(getError);
  const loading = useSelector(getLoading);
  const success = useSelector(getSuccess);

  const refButtonSubmit = useRef();
  const refInputEmailAddress = useRef();
  const refInputPassword = useRef();
  const refInputPhoneNumber = useRef();
  const refInputCheckbox = useRef();

  const [isGeneralError, setIsGeneralError] = useState(false);
  const [isSubmissionBlocked, setIsSubmissionBlocked] = useState(true);
  const [fieldsTouched, setFieldsTouched] = useState({});

  const FORM = useForm();
  const REQUEST = useApplicationRequest();

  const values = FORM.getValues();

  const dataButtonSubmit = data?.button?.[0];
  const isCheckboxVisible =
    data?.terms_and_conditions_link ||
    data?.terms_and_conditions_modal?.content?.[0]?.content?.[0]?.text;

  useEffect(() => {
    const numberOfFields = isCheckboxVisible ? 4 : 3;

    if (Object.keys(fieldsTouched).length >= numberOfFields) {
      setIsSubmissionBlocked(Object.keys(FORM.errors).length !== 0);
    }
  }, [FORM.errors, values]);

  useEffect(() => {
    if (success.type === 'application' && success.didSucceed) {
      // cleanup; ensures you can go back to the YAPA page
      dispatch(setSuccess({ type: '', didSucceed: false }));

      if (application.URL) {
        window.location.href = application.URL;
      } else {
        setIsGeneralError(true);
      }
    } else if (error.type === 'application' && error.isError) {
      setIsGeneralError(true);
    }
  }, [application, dispatch, error, success]);

  const onSubmit = values => {
    REQUEST.submitForm(formName)(values);
  };

  const reset = () => {
    FORM.reset();

    setIsGeneralError(false);
    setIsSubmissionBlocked(true);
    setFieldsTouched({});
  };

  if (isGeneralError) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <p>
            Something went wrong
            <br />
            Please try again or contact us:{' '}
            <a href="tel:(800) 458-1605">(800) 458-1605</a>
          </p>
          <Link onClick={reset} role="button" variant="lg">
            Try again
          </Link>
        </div>
      </div>
    );
  }

  return (
    <FormContext {...{ fieldsTouched, setFieldsTouched, ...FORM }}>
      <form onSubmit={FORM.handleSubmit(onSubmit)}>
        <EmailInput
          name={`${formName}-email`}
          ref={refInputEmailAddress}
          refNextInputToFocusOnEnterKeyPressed={refInputPassword}
        />

        <PasswordInput
          name={`${formName}-password`}
          ref={refInputPassword}
          refNextInputToFocusOnEnterKeyPressed={refInputPhoneNumber}
        />

        <PhoneNumberInput
          name={`${formName}-phone`}
          ref={refInputPhoneNumber}
          refNextInputToFocusOnEnterKeyPressed={
            isCheckboxVisible ? refInputCheckbox : refButtonSubmit
          }
        />

        {isCheckboxVisible ? (
          <CheckboxInput
            name={`${formName}-terms`}
            ref={refInputCheckbox}
            refNextInputToFocusOnEnterKeyPressed={refButtonSubmit}
            linkLegal={data?.terms_and_conditions_link}
            data={data?.terms_and_conditions_modal}
            openModalTerms={openModalTerms}
          />
        ) : (
          <div />
        )}

        <div />

        <ButtonWithStoryblokStyles data={dataButtonSubmit}>
          <ButtonSubmit
            ref={refButtonSubmit}
            disabled={isSubmissionBlocked}
            formNoValidate
            loading={loading.type === 'application' && loading.isLoading}
            label={dataButtonSubmit?.label || 'Lock My Rate Now'}
            labelWhileLoading="Processing"
          />
        </ButtonWithStoryblokStyles>

        {data?.disclaimer_below_button && (
          <p className={styles.disclaimer}>{data?.disclaimer_below_button}</p>
        )}
      </form>
    </FormContext>
  );
};

FormLockMyRate.propTypes = {
  data: PropTypes.shape({
    button: PropTypes.any,
    terms_and_conditions_link: PropTypes.string,
    terms_and_conditions_modal: PropTypes.shape(),
    disclaimer_below_button: PropTypes.string,
  }),
  formName: PropTypes.string,
  openModalTerms: PropTypes.bool,
};
