import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

// Hook to return editable Storyblok component with all properties as "data" props.

export const useStoryblokComponent = props => {
  let { data } = props;

  if (!data) {
    data = props;
  }

  if (!data) return null;

  const StoryblokComponent = ({ children }) => (
    <SbEditable content={data}>{children}</SbEditable>
  );

  StoryblokComponent.propTypes = {
    children: PropTypes.children,
  };

  return {
    StoryblokComponent,
    data,
  };
};
