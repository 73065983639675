import React from 'react';
import PropTypes from 'prop-types';

import { RichText } from 'components/elements/RichText';
import { LogoSVG } from 'base/assets/icons';

import styles from './styles.module.css';

export const Footer = ({ data }) => (
  <section className="section-wide">
    <footer className={styles.footer}>
      <div className={`${styles.content} section-with-top-border`}>
        <a href="/">
          <img
            src={data?.logo?.filename || LogoSVG}
            alt="youarepreapproved-logo"
            loading="lazy"
            decoding="async"
          />
        </a>
      </div>

      <div className={`section-with-top-border ${styles.subfooter}`}>
        {data?.description ? (
          <RichText data={data?.description} className={styles.copyright} />
        ) : (
          <p className={styles.copyright}>
            Copyright © 2020 YouArePreApproved.com
          </p>
        )}
      </div>
    </footer>
  </section>
);

Footer.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.shape({
      filename: PropTypes.string,
    }),
    description: PropTypes.any,
  }),
};
