import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export const Input = forwardRef(
  ({ Component = 'input', errorMessage, ...rest }, ref) => {
    const onFocus = e => e?.target?.select();

    const onKeyPress = e => {
      if (!(e?.key in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9])) {
        e.preventDefault();
      }
    };

    const props = {
      ref,
      onFocus,
      onKeyPress: rest.pattern === '\\d*' ? onKeyPress : undefined,
    };

    return (
      <>
        <Component ref={ref} className={styles.input} {...props} {...rest} />
        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </>
    );
  }
);

Input.propTypes = {
  Component: PropTypes.string,
  errorMessage: PropTypes.string,
};

Input.displayName = 'Input';
