import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export const InputWrapper = ({ label, htmlFor, children, ...rest }) => (
  <div className={styles.wrapper} {...rest}>
    {label && (
      <label htmlFor={htmlFor} className={styles.label}>
        {label}
      </label>
    )}

    {children}
  </div>
);

InputWrapper.propTypes = {
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  children: PropTypes.node,
};
