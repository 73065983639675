import React from 'react';
import PropTypes from 'prop-types';

import { useOverlayBehaviorFix } from 'base/utils/useOverlayBehaviorFix';

import { CloseSVG } from 'base/assets/icons';

import styles from './styles.module.css';

export const MobileOverlay = ({
  isOpened,
  closeOverlay,
  overlayHeight,
  label,
  withHeader = false,
  children,
}) => {
  useOverlayBehaviorFix(isOpened);

  const stopPropagation = event => event.stopPropagation();

  const is_opened = isOpened.toString();

  return (
    <div
      className={styles.background}
      style={{ zIndex: withHeader ? -1 : 5 }}
      is_opened={is_opened}
      onClick={closeOverlay}
    >
      <div
        className={styles.overlay}
        style={{ '--overlay-height': `${overlayHeight || 80}vh` }}
        is_opened={is_opened}
        onClick={stopPropagation}
      >
        <div className={styles.topBarPlaceholder} />
        <div className={styles.topBar} is_opened={is_opened}>
          {label || <span />}
          <div className={styles.buttonClose} onClick={closeOverlay}>
            <img
              src={CloseSVG}
              alt="close-icon"
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>

        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

MobileOverlay.propTypes = {
  isOpened: PropTypes.bool,
  closeOverlay: PropTypes.func,
  overlayHeight: PropTypes.number,
  label: PropTypes.string,
  withHeader: PropTypes.bool,
  children: PropTypes.node,
};
