import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EyeSVG, EyeCrossedOutSVG } from 'base/assets/icons';

import styles from './styles.module.css';

export const ButtonTogglePasswordVisibility = ({ refInput }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onClick = () => {
    if (refInput?.current) {
      refInput.current.focus?.();

      if (isPasswordVisible) {
        refInput.current.type = 'password';
      } else {
        refInput.current.type = 'text';
      }
    }

    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={styles.button} onClick={onClick}>
      {isPasswordVisible ? (
        <img
          src={EyeCrossedOutSVG}
          alt="eye-crossed-out-icon"
          loading="lazy"
          decoding="async"
        />
      ) : (
        <img src={EyeSVG} alt="eye-open-icon" loading="lazy" decoding="async" />
      )}
    </div>
  );
};

ButtonTogglePasswordVisibility.propTypes = {
  refInput: PropTypes.shape({ current: PropTypes.any }),
};
