import React from 'react';
import { shape } from 'prop-types';
import SbEditable from 'storyblok-react';

import { getSectionId } from 'base/utils/getSectionId';

import { BlobSVG } from 'base/assets/decorations';
import { Step } from './Step';

import styles from './styles.module.css';

export function Steps({ data }) {
  return (
    <SbEditable content={data}>
      <div id={getSectionId(data)} className={styles.wrapper}>
        <section className="section">
          <h1
            data-testid="steps-section-title"
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: data?.title }}
          />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />

          <div className={styles.content}>
            <div className={styles.steps}>
              {(data?.steps || []).map((step, stepNr) => (
                <Step key={step._uid} {...{ stepNr, ...step }} />
              ))}
            </div>

            <picture className={styles.image}>
              <source srcSet={data?.image_webp?.filename} type="image/webp" />
              <img
                src={data?.image?.filename}
                width="250"
                loading="lazy"
                decoding="async"
                className={styles.image}
                style={{ height: 'auto' }}
              />
            </picture>

            <div className={styles.decorations}>
              <img src={BlobSVG} loading="lazy" decoding="async" />
              <img src={BlobSVG} loading="lazy" decoding="async" />
            </div>
          </div>
        </section>
      </div>
    </SbEditable>
  );
}

Steps.propTypes = {
  data: shape(),
};
