import React, { useState } from 'react';
import { func, shape, string } from 'prop-types';

import { ButtonWithStoryblokStyles } from 'components/elements//ButtonWithStoryblokStyles';

import { InputWrapper } from 'components/elements/Inputs/InputWrapper';
import { CurrencyInput } from 'components/elements/Inputs/CurrencyInput';
import { MultiSelectInput } from 'components/elements/Inputs/MultiSelectInput';
import { SelectDropdownInput } from 'components/elements/Inputs/SelectDropdownInput';
import { SwitchInput } from 'components/elements/Inputs/SwitchInput';
import { Tip } from 'components/elements/Tip';

import { getValues } from './getValues';
import styles from './styles.module.css';

export const FormFilterOffers = ({
  name,
  setValue: setFormValue,
  register,
  getValues: getFormValues,
  data = {},
  ratesParams: rates,
}) => {
  const [ratesParams, setRatesParams] = useState(rates);
  const [isCashOutFieldVisible, setIsCashOutFieldVisible] = useState(false);
  const [sumNewLoanAmount, setSumNewLoanAmount] = useState(
    parseInt(ratesParams.LoanAmount || 0) + parseInt(ratesParams.CashOut || 0)
  );

  if (ratesParams === null) {
    return null;
  }

  const valuesAmortTerms = data?.filter_loan_term || [];
  const defaultAmortTerms = valuesAmortTerms
    .filter(option => option.is_default)
    .map(option => option.value);

  const valuesLoanPurpose = getValues(data, 'LOAN_TYPE');
  const valuesPropertyType = getValues(data, 'PROPERTY_TYPE');
  const valuesDwellingType = getValues(data, 'DWELLING_TYPE');
  const valuesLoanPoints = getValues(data, 'LOAN_POINTS');

  const style = {
    '--background-active':
      data?.color_background_select_field_option_active?.color ||
      'var(--background-primary-active)',
    '--background-hover':
      data?.color_background_select_field_option_hover?.color ||
      'var(--background-primary)',
    '--color-placeholder':
      data?.color_background_select_field_option_placeholder?.color ||
      'var(--color-font-body)',
  };

  const setLoanPurposeValue = (name, value) => {
    setValue(name, value);
    setIsCashOutFieldVisible(value === 'Refi');
  };

  const setValue = (fieldName, value) => {
    if (fieldName === `${name}-loanAmount`) {
      const result =
        parseInt(value || 0) +
        parseInt(getFormValues()[`${name}-cashOut`] || 0);
      setSumNewLoanAmount(result);
    }

    if (fieldName === `${name}-cashOut`) {
      const result =
        parseInt(value || 0) +
        parseInt(getFormValues()[`${name}-loanAmount`] || 0);
      setSumNewLoanAmount(result);
    }

    setFormValue(fieldName, value);
    setRatesParams(prevRates => ({ ...prevRates, [fieldName]: value }));
  };

  return (
    <>
      <input type="hidden" name={`${name}-bots`} />

      <input
        type="hidden"
        name={`${name}-zip`}
        value={ratesParams.Zip}
        ref={register({ required: true })}
      />

      <InputWrapper
        label={
          data?.filter_cashout && isCashOutFieldVisible
            ? 'Current Mortgage Balance(s)'
            : 'Loan Amount'
        }
        htmlFor={`${name}-loanAmount`}
      >
        <CurrencyInput
          data-testid="loan-amount"
          name={`${name}-loanAmount`}
          value={ratesParams[`${name}-loanAmount`] || ratesParams.LoanAmount}
          setValue={setValue}
          register={register}
          required={true}
        />
      </InputWrapper>

      {data?.filter_cashout && isCashOutFieldVisible && (
        <InputWrapper label="Cashout" htmlFor={`${name}-cashOut`}>
          <CurrencyInput
            name={`${name}-cashOut`}
            value={ratesParams[`${name}-cashOut`] || ratesParams.CashOut || '0'}
            setValue={setValue}
            register={register}
            required={true}
          />
        </InputWrapper>
      )}

      {data?.filter_cashout && isCashOutFieldVisible && (
        <InputWrapper label="New Loan Amount" htmlFor={`${name}-cashOut`}>
          <CurrencyInput value={sumNewLoanAmount} disabled={true} />
        </InputWrapper>
      )}

      <InputWrapper label="Property Value" htmlFor={`${name}-propertyValue`}>
        <CurrencyInput
          data-testid="property-value"
          name={`${name}-propertyValue`}
          value={
            ratesParams[`${name}-propertyValue`] || ratesParams.PropertyValue
          }
          setValue={setValue}
          register={register}
          required={true}
        />
      </InputWrapper>

      <InputWrapper label="Loan Term" htmlFor={`${name}-amortTerm`}>
        <ButtonWithStoryblokStyles data={data?.filter_loan_term_button?.[0]}>
          <MultiSelectInput
            name={`${name}-amortTerm`}
            value={defaultAmortTerms}
            setValue={setValue}
            register={register}
            required={true}
            options={valuesAmortTerms}
            data-testid="term-filter"
          />
        </ButtonWithStoryblokStyles>
      </InputWrapper>

      {data?.filter_veteran && (
        <InputWrapper label="Veteran" htmlFor={`${name}-veteran`}>
          <SwitchInput
            name={`${name}-veteran`}
            value={ratesParams.Veteran}
            setValue={setValue}
            register={register}
            values={{ true: 'Yes', false: 'No' }}
          />
        </InputWrapper>
      )}

      <InputWrapper
        data-testid="purpose-filter"
        label="Loan Type"
        htmlFor={`${name}-loanPurpose`}
        style={style}
      >
        <SelectDropdownInput
          name={`${name}-loanPurpose`}
          value={data?.default_loan_type}
          setValue={setLoanPurposeValue}
          register={register}
          required={true}
          options={valuesLoanPurpose}
        />
      </InputWrapper>

      <InputWrapper
        data-testid="property-type-filter"
        label="Property Type"
        inputId="propertyType"
        style={style}
      >
        <SelectDropdownInput
          name={`${name}-propertyType`}
          value={data?.default_property_type}
          setValue={setValue}
          register={register}
          required={true}
          options={valuesPropertyType}
        />
      </InputWrapper>

      <InputWrapper label="Dwelling Type" inputId="dwellingType" style={style}>
        <SelectDropdownInput
          name={`${name}-dwellingType`}
          value={data?.default_dwelling_type}
          setValue={setValue}
          register={register}
          required={true}
          options={valuesDwellingType}
        />
      </InputWrapper>

      <InputWrapper data-testid="points-filter" style={style}>
        <div className={styles.labelLoanPoints}>
          <label htmlFor="points">Loan Points</label>
          {data?.filter_loan_points_tip && (
            <Tip>{data.filter_loan_points_tip}</Tip>
          )}
        </div>
        <SelectDropdownInput
          name={`${name}-points`}
          value={data?.default_loan_points}
          setValue={setValue}
          register={register}
          required={true}
          options={valuesLoanPoints}
        />
      </InputWrapper>
    </>
  );
};

FormFilterOffers.propTypes = {
  name: string,
  setValue: func,
  register: func,
  getValues: func,
  data: shape(),
  ratesParams: shape(),
};
