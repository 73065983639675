import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { applicationQuery } from 'base/store/actions';
import { getOfferId, getProductId } from 'base/store/selectors';

export const useApplicationRequest = () => {
  const dispatch = useDispatch();
  const offerId = useSelector(getOfferId);
  const productId = useSelector(getProductId);
  const [submissionCount, setSubmissionCount] = useState(0);

  const submitForm = name => data => {
    const inputNamesMapped = {
      email: data[`${name}-email`],
      phone: data[`${name}-phone`],
      password: data[`${name}-password`],
    };

    setSubmissionCount(submissionCount + 1);

    dispatch(
      applicationQuery({
        ...inputNamesMapped,
        offerId,
        productId,
      })
    );
  };

  return { submitForm };
};
