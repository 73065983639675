import React from 'react';
import SbEditable from 'storyblok-react';
import PropTypes from 'prop-types';

import { RichText } from 'components/elements/RichText';

import { removeEmpty } from 'base/utils/removeEmpty';

export const InformationBox = ({ data = {} }) => {
  const {
    color_font,
    color_background,
    color_shadow,
    color_border,

    border_radius = 0,
    border_width = 0,

    override_background,
    override_shadow,
    override_border,
    top_spacing,
    bottom_spacing,
  } = data;

  const topSpacing = `${top_spacing ?? 32}px`;
  const bottomSpacing = `${bottom_spacing ?? 32}px`;

  const customStyle = {
    '--color-font-heading': color_font?.color,
    '--color-font-body': color_font?.color,
    color: color_font?.color,
    background:
      override_background ||
      color_background?.color ||
      'var(--color-background-light)',

    boxShadow:
      override_shadow ||
      (color_shadow?.color && `0px 10px 35px ${color_shadow?.color}`),

    borderRadius: `${border_radius}px`,
    border:
      override_border ||
      `${border_width}px solid ${color_border?.color ||
        'var(--color-primary)'}`,
    marginTop: topSpacing,
    marginBottom: bottomSpacing,
    paddingTop: topSpacing,
    paddingBottom: bottomSpacing,
  };

  const style = removeEmpty(customStyle);

  return (
    <section className="section-wide">
      <SbEditable content={data}>
        <div>
          <RichText data={data.content_above} />
          <RichText className="box" style={style} data={data.body} />
          <RichText data={data.content_below} />
        </div>
      </SbEditable>
    </section>
  );
};

InformationBox.propTypes = {
  data: PropTypes.shape(),
};
