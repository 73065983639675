import { useEffect, useState } from 'react';

/* handles jumpy behaviour of fixed elements caused by IOS bottom bar */
export const useOverlayBehaviorFix = isOverlayOpened => {
  const [scrollOffset, setScrollOffset] = useState(0);

  const getDOMObjects = () => ({
    html: document.getElementsByTagName('html')[0],
    body: document.getElementsByTagName('body')[0],
  });

  useEffect(() => {
    const isViewportMobile = window.innerWidth < 992;

    if (isViewportMobile) {
      const event = isOverlayOpened ? onOverlayOpened : onOverlayClosed;

      event();
    }
  }, [isOverlayOpened]);

  const onOverlayOpened = () => {
    const { html, body } = getDOMObjects();

    const pageYOffset = window?.pageYOffset || 0;

    if (html) {
      html.style['scroll-behavior'] = 'auto';
    }

    if (body) {
      body.style['position'] = 'fixed';
      body.style['top'] = `-${pageYOffset}px`;
      body.style['overflow-y'] = 'scroll';
    }

    setScrollOffset(pageYOffset);
  };

  const onOverlayClosed = () => {
    const { html, body } = getDOMObjects();

    if (body) {
      body.style['position'] = 'static';
    }

    window.scroll({ top: scrollOffset, behavior: 'auto' });

    if (html) {
      html.style['scroll-behavior'] = 'smooth';
    }
  };
};
