import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/elements/Buttons/Button';
import { Spinner } from 'components/elements/Spinner';

export const ButtonSubmit = forwardRef(
  ({ loading, label, labelWhileLoading, ...rest }, ref) => (
    <Button ref={ref} type="submit" disabled={loading} {...rest}>
      {loading ? labelWhileLoading : label}
      {loading && <Spinner theme="white" variant="sm" />}
    </Button>
  )
);

ButtonSubmit.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
  labelWhileLoading: PropTypes.string,
};

ButtonSubmit.displayName = 'ButtonSubmit';
