import React from 'react';
import { array, oneOfType, shape, string, boolean } from 'prop-types';
import { InlineWidget } from 'react-calendly';
import {
  render,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  MARK_CODE,
  MARK_LINK,
} from 'storyblok-rich-text-react-renderer';

import { useClientValuesResolverContext } from 'base/context/ClientValuesResolverContext';
import { useClientData } from 'base/utils/useClientData';
import { Bloks } from 'base/utils/bloks';

const resolve = (children, resolvers = [child => child]) =>
  resolvers.reduce((child, resolver) => resolver(child), children);

export function RichText({ data, isCalendly, customFunctions, ...rest }) {
  const clientValues = useClientValuesResolverContext();
  const clientData = useClientData();

  const handleClickCustomFunction = functionName =>
    customFunctions[functionName]();

  if (!data) {
    return <div />;
  }

  return (
    <div {...rest}>
      {render(data, {
        markResolvers: {
          // eslint-disable-next-line react/display-name
          [MARK_CODE]: children => {
            if (isCalendly) {
              return clientData.deleteSpecialCharacters(
                clientData.richTextResolver(children)
              );
            }

            return (
              <span
                dangerouslySetInnerHTML={{
                  __html: resolve(children, [
                    clientValues.richTextResolver,
                    clientData.richTextResolver,
                  ]),
                }}
              />
            );
          },
          [MARK_LINK]: (children, props) =>
            renderLink(children, props, handleClickCustomFunction),
        },
        nodeResolvers: {
          [NODE_IMAGE]: (children, props) => (
            <img className="global-image" {...props} />
          ),
          [NODE_PARAGRAPH]: (children, props) => {
            if (isCalendly) {
              const calendlyURL = children.join('');
              return (
                <InlineWidget url={calendlyURL} styles={{ height: 700 }} />
              );
            }

            return <p {...props}>{children}</p>;
          },
        },
        blokResolvers: Bloks,
      })}
    </div>
  );
}

RichText.propTypes = {
  data: oneOfType([shape(), string]),
  resolvers: array,
  isCalendly: boolean,
  customFunctions: shape(),
};

const renderLink = (children, props, handleClickCustomFunction) => {
  const handleOnClick = event => {
    event.preventDefault();
    const customFunctionName = props.href.replace('#custom-', '');
    handleClickCustomFunction(customFunctionName);
  };

  if (props.href.includes('#custom')) {
    return (
      <a {...props} onClick={handleOnClick}>
        {children}
      </a>
    );
  }
  return <a {...props}>{children}</a>;
};
