import React from 'react';
import PropTypes from 'prop-types';

import { useStoryblokComponent } from 'base/hooks/useStoryblokComponent';

import { createBlok } from 'base/utils/bloks';

import styles from './styles.module.css';

export const FlexibleColumn = props => {
  const { StoryblokComponent, data } = useStoryblokComponent(props);

  const columnNumber =
    data.columns.length % 4 === 0 ? 4 : data.columns.length % 4;

  return (
    <StoryblokComponent>
      <div
        className={`${styles.row} ${
          data.is_vertical_centered ? styles.rowAlignCenter : ''
        }`}
      >
        {data.columns.map(column => (
          <div
            className={`${styles.column}
            ${styles[`column${columnNumber}`]}
          `}
            key={column._uid}
          >
            {createBlok(column.component, {
              key: column._uid,
              data: column,
            })}
          </div>
        ))}
      </div>
    </StoryblokComponent>
  );
};

FlexibleColumn.propTypes = {
  columns: PropTypes.any,
};
