import React, { useState } from 'react';
import SbEditable from 'storyblok-react';
import PropTypes from 'prop-types';

import { getSectionId } from 'base/utils/getSectionId';
import { DotsPNG } from 'base/assets/decorations';

import { LinkButton } from 'components/elements/Links/LinkButton';

import { Question } from './Question';

import styles from './styles.module.css';

export const FAQ = ({ data }) => {
  const activeQuestionState = useState();

  return (
    <SbEditable content={data}>
      <div id={getSectionId(data)} className={styles.wrapper}>
        <section className="section">
          <div>
            <h1
              data-testid="FAQ-section-title"
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: data?.title }}
            />

            <div data-testid="FAQ-section" className={styles.questions}>
              {(data?.questions || []).map((question, questionNr) => (
                <Question
                  key={question._uid}
                  {...{ questionNr, ...question, activeQuestionState }}
                />
              ))}
            </div>

            <div className={styles.cta}>
              <h2>{data?.cta_heading}</h2>
              <LinkButton to="#contact" theme="secondary">
                {data?.cta_button}
              </LinkButton>
              <div className={styles.decorations}>
                <img
                  src={DotsPNG}
                  alt="dots-decoration"
                  loading="lazy"
                  decoding="async"
                />
                <img
                  src={DotsPNG}
                  alt="dots-decoration"
                  loading="lazy"
                  decoding="async"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </SbEditable>
  );
};

FAQ.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    questions: PropTypes.array,
    cta_heading: PropTypes.any,
    cta_button: PropTypes.any,
  }),
};
