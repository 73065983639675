import React from 'react';
import { shape, string } from 'prop-types';
import SbEditable from 'storyblok-react';
import { useSelector } from 'react-redux';

import { getLoading } from 'base/store/selectors';

import { FormGetOffers } from 'components/sections/Forms/FormGetOffers';
import { Spinner } from 'components/elements/Spinner';

import { List } from './List';

import styles from './styles.module.css';

export function Offers({ data, storyblokMode, location }) {
  const loading = useSelector(getLoading);

  return (
    <section className="section-wide">
      <SbEditable content={data}>
        <div className={styles.offers}>
          <FormGetOffers data={data} />

          {loading.type === 'rates' && loading.isLoading ? (
            <div>
              <Spinner label="Loading Offers..." />
            </div>
          ) : (
            <List {...{ storyblokMode, data, location }} />
          )}
        </div>
      </SbEditable>
    </section>
  );
}

Offers.propTypes = {
  data: shape(),
  storyblokMode: string,
  location: shape(),
};
