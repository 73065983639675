import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';

import { getOfferWeb, getSuccess, getRatesParams } from 'base/store/selectors';

import { useRatesRequest } from 'base/utils/API/requests/useRatesRequest';
import { useZipRequest } from 'base/utils/API/requests/useZipRequest';

import { ButtonSubmit } from 'components/elements/Buttons/ButtonSubmit';
import { reset } from 'base/store/actions';

export const useSubmit = ({ pageContext }) => {
  const ratesRequest = useRatesRequest();
  const zipRequest = useZipRequest();
  const dispatch = useDispatch();
  const offerWeb = useSelector(getOfferWeb);
  const success = useSelector(getSuccess);
  const ratesParams = useSelector(getRatesParams);
  const refButtonSubmit = useRef();
  const [isSubmissionBlocked, setIsSubmissionBlocked] = useState(true);

  useEffect(() => {
    dispatch(reset());
  }, []);

  const lockSubmit = () => setIsSubmissionBlocked(true);
  const unlockSubmit = () => setIsSubmissionBlocked(false);

  // @TODO: Use Regexp
  const isNoRates = ratesParams.ApiMessage?.toLowerCase().includes('no rates');

  const submitRatesRequest = () => {
    ratesRequest.submitRequest();
  };

  const submitZipCheckRequest = (...params) =>
    zipRequest.submitRequest(...params);

  const onClick = e => {
    e.preventDefault();

    if (!isSubmissionBlocked) {
      // Remove it for clients who don't need offers
      if (!isNoRates) {
        submitRatesRequest();
      }

      navigate(pageContext?.offerWebToPathNameMap?.[offerWeb]);
    }
  };

  const focusSubmit = () => refButtonSubmit?.current?.focus();

  useEffect(() => {
    if (success.type === 'zip' && success.didSucceed) {
      unlockSubmit();
    }
  }, [success.type, success.didSucceed]);

  const button = (
    <ButtonSubmit
      ref={refButtonSubmit}
      aria-disabled={isSubmissionBlocked?.toString()}
      onClick={onClick}
      label="Get My Best Rates!"
      data-testid="hero-button"
    />
  );

  return {
    submitRatesRequest,
    submitZipCheckRequest,
    lockSubmit,
    unlockSubmit,
    focusSubmit,
    button,
  };
};
