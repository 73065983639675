import React from 'react';
import { number, oneOfType, string } from 'prop-types';

import styles from './styles.module.css';

export function Input({ Component = 'input', errorMessage, ...rest }) {
  return (
    <>
      <Component className={styles.input} {...rest} />
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </>
  );
}

Input.propTypes = {
  Component: string,
  errorMessage: string,
};

export function HiddenTagInput({ name, value }) {
  return value ? (
    <input type="hidden" id={`${name}_tag`} name={name} value={value} />
  ) : null;
}

HiddenTagInput.propTypes = {
  name: string,
  value: oneOfType([string, number]),
};
