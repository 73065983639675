/* EXAMPLE OF A RESPONSE FOR A WRONG PHONE NUMBER (1231231234)
  {
    "code": 20404,
    "message": "The requested resource /PhoneNumbers/+11231231234 was not found",
    "more_info": "https://www.twilio.com/docs/errors/20404",
    "status": 404
  }
*/

/* EXAMPLE OF A RESPONSE FOR A CORRECT PHONE NUMBER (8002141962)
  {
    "caller_name": null,
    "country_code": "US",
    "phone_number": "+18002141962",
    "national_format": "(800) 214-1962",
    "carrier": {
        "mobile_country_code": null,
        "mobile_network_code": null,
        "name": null,
        "type": null,
        "error_code": 60600
    },
    "add_ons": null,
    "url": "https://lookups.twilio.com/v1/PhoneNumbers/+18002141962?Type=carrier"
  }
*/

export const validatePhoneNumber = phone =>
  fetch('/.netlify/functions/validation-phone', {
    method: 'POST',
    body: JSON.stringify({ phone }),
  })
    .then(response => response.json())
    .then(
      json =>
        new Promise((resolve, reject) => {
          if (json?.phone_number) {
            resolve(json.phone_number);
          } else {
            reject(json);
          }
        })
    );
