import React from 'react';
import SbEditable from 'storyblok-react';
import PropTypes from 'prop-types';

import { getSectionId } from 'base/utils/getSectionId';

import { FormContact } from 'components/sections/Forms/NoJS/FormContact';
import { ContactLink } from './ContactLink';

import styles from './styles.module.css';

export const Contact = ({ data }) => (
  <SbEditable content={data}>
    <section id={getSectionId(data)} className="section-wide">
      <div className={styles.contactLinks}>
        <div>
          <h1
            data-testid="contact-section-title"
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: data?.title }}
          />

          {(data?.contact_links || []).map(contact_link => (
            <ContactLink key={contact_link._uid} data={contact_link} />
          ))}
        </div>

        <FormContact />
      </div>
    </section>
  </SbEditable>
);

Contact.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    contact_links: PropTypes.array,
  }),
};
