import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { shape, string } from 'prop-types';

import { getSuccess } from 'base/store/selectors';

import { useResNumRatesRequest } from 'base/utils/API/requests/useResNumRatesRequest';

import { useInputAppearance } from '../useInputAppearance';
import { useRequestActions } from '../useRequestActions';
import { useSubmit } from '../useSubmit';

import { RichText } from 'components/elements/RichText';
import { Input } from '../Input';

import { CheckmarkCircleSVGlement } from 'base/assets/icons';

import styles from './styles.module.css';

const MAX_LENGTH_ZIP = 5;

export function FormPreloadFromPreapproval({
  data,
  creative,
  dataset,
  source,
  version,
  pageContext,
}) {
  // isEightDigitResNum determines if we should show 1 field with 8 digits or 3 fields with 3 digits each.
  const isEightDigitResNum = version === 'preapproval-8';
  const MAX_RESNUM_LENGTH = isEightDigitResNum ? 8 : 9;
  const MAX_LENGTH_RES_NUM = [3, 3, Math.floor(MAX_RESNUM_LENGTH / 3)];

  const resNumRatesRequest = useResNumRatesRequest();
  const success = useSelector(getSuccess);

  const refInputResNumInputWide = useRef();
  const refInputResNumInput1 = useRef();
  const refInputResNumInput2 = useRef();
  const refInputResNumInput3 = useRef();
  const refInputZip = useRef();

  const [inputResNumBox, setInputResNumBox] = useState([null, null, null]);
  const [inputResNumWideBox, setInputResNumWideBox] = useState(null);

  const [inputZip, setInputZip] = useState();

  const appearanceResNum = useInputAppearance();
  const appearanceZip = useInputAppearance();

  const [wasResNumChanged, setWasResNumChanged] = useState();

  const isZipFilled = inputZip?.length === MAX_LENGTH_ZIP;

  const {
    submitZipCheckRequest,
    lockSubmit,
    unlockSubmit,
    focusSubmit,
    button,
  } = useSubmit({ pageContext });

  const onResNumRatesLoading = () => {
    appearanceResNum.setLoading();
  };

  const onZipLoading = () => {
    appearanceZip.setLoading();
  };

  const onResNumRatesSuccess = () => {
    appearanceResNum.setSuccess();
    appearanceZip.setDefault();

    checkZip();
  };

  const onZipSuccess = () => {
    appearanceZip.setSuccess();
  };

  const onResNumRatesError = () => {
    appearanceResNum.setError();
  };

  const onZipError = () => {
    appearanceZip.setError();
  };

  useRequestActions({
    loading: { resNumRates: onResNumRatesLoading, zip: onZipLoading },
    success: { resNumRates: onResNumRatesSuccess, zip: onZipSuccess },
    error: { resNumRates: onResNumRatesError, zip: onZipError },
  });

  const focusNextInput = (value, maxLength, nr) => {
    if (value?.length === maxLength) {
      [
        refInputResNumInput1,
        refInputResNumInput2,
        refInputResNumInput3,
        refInputZip,
      ][nr + 1]?.current.focus();
    }
  };

  const onChangeResNumInput1 = e => onChangeResNumInput(e, 0);
  const onChangeResNumInput2 = e => onChangeResNumInput(e, 1);
  const onChangeResNumInput3 = e => onChangeResNumInput(e, 2);
  const onChangeResNumInputWide = e => onChangeResNumInput(e);

  const onChangeResNumInput = (e, nr) => {
    setWasResNumChanged(true);
    lockSubmit();
    appearanceResNum.setDefault();
    appearanceZip.setDefault();

    if (!isEightDigitResNum) {
      let copy = inputResNumBox;
      copy[nr] = e.target.value;
      if (inputResNumBox.join('').length === MAX_RESNUM_LENGTH) {
        submitResNumRatesRequest();
      }

      setInputResNumBox(copy);
      setInputZip('');
      focusNextInput(e?.target?.value, MAX_LENGTH_RES_NUM[nr], nr);
      return;
    }

    const value = e.target.value;

    if (value.length === MAX_RESNUM_LENGTH) {
      submitResNumRatesRequest(value);
    }

    setInputResNumWideBox(value);
    setInputZip('');
    focusNextInput(value, 8, 2);
  };

  const onChangeZip = e => {
    lockSubmit();

    appearanceZip.setDefault();

    setInputZip(e.target.value);
  };

  useEffect(() => {
    checkZip();
  }, [inputZip]);

  useEffect(() => {
    if (success.type === 'zip' && success.didSucceed && isZipFilled) {
      appearanceResNum.setSuccess();
      appearanceZip.setSuccess();
      focusSubmit();
      unlockSubmit();
    }
  }, [success.type]);

  const checkZip = () => {
    if (!isZipFilled) return;

    submitZipCheckRequest(
      isEightDigitResNum ? inputResNumWideBox : inputResNumBox.join(''),
      inputZip
    );
  };

  const onBlur = e => {
    // prevent resNumRates request on blur between preapproval number inputs
    if (e?.relatedTarget?.getAttribute('triggerblur') === 'false') {
      return;
    }

    // prevent resNumRates request if preapproval number was not edited
    if (wasResNumChanged === false) {
      return;
    }
  };

  const onPaste = e => {
    e.preventDefault();

    setWasResNumChanged(true);

    appearanceResNum.setDefault();
    appearanceZip.setDefault();

    const pastedResNum = (e.clipboardData || window.clipboardData)
      .getData('text')
      .replace(/\D/g, '')
      .slice(0, MAX_RESNUM_LENGTH);

    let copy = inputResNumBox;
    copy[0] = pastedResNum.slice(0, 3);
    copy[1] = pastedResNum.slice(3, 6);
    copy[2] = pastedResNum.slice(6, MAX_RESNUM_LENGTH);

    setInputZip('');
    isEightDigitResNum
      ? setInputResNumWideBox(pastedResNum)
      : setInputResNumBox(copy);

    if (pastedResNum.length < 3) {
      refInputResNumInput1?.current.focus();
    } else if (pastedResNum.length < 6) {
      refInputResNumInput2?.current.focus();
    } else if (pastedResNum.length < MAX_RESNUM_LENGTH) {
      refInputResNumInput3?.current.focus();
    } else {
      refInputZip?.current.focus();
      submitResNumRatesRequest(pastedResNum);
    }
  };

  const submitResNumRatesRequest = value => {
    const resNum = value || inputResNumBox.join('');

    if (resNum.length === MAX_RESNUM_LENGTH) {
      resNumRatesRequest.submitRequest({ resNum, creative, dataset, source });
    }
  };

  return (
    <form
      id="form-get-your-best-rate"
      name="form-get-your-best-rate"
      className={styles.form}
    >
      <input type="hidden" name="bots" />

      <div className={styles.fieldset}>
        <h4 className={`heading-gradient-secondary ${styles.number}`}>1</h4>
        <div>
          <label htmlFor="get-your-best-rate" data-testid="label-preapproval">
            <h5 className={styles.label}>
              Enter Your PreApproval Number
              {appearanceResNum.variant === 'success' && (
                <CheckmarkCircleSVGlement />
              )}
            </h5>
          </label>
          {isEightDigitResNum ? (
            <div className={styles.preApprovalNumberInputsWide}>
              <Input
                required
                autoFocus
                id="get-your-best-rate"
                ref={refInputResNumInputWide}
                type="text"
                placeholder="00000000"
                pattern="\d*"
                value={inputResNumWideBox}
                onBlur={onBlur}
                onChange={onChangeResNumInputWide}
                onPaste={onPaste}
                variant={appearanceResNum.variant}
                triggerblur="false"
                minLength={8}
                maxLength={8}
              />
            </div>
          ) : (
            <div className={styles.preApprovalNumberInputs}>
              <Input
                required
                autoFocus
                data-testid="input-preapproval"
                id="get-your-best-rate"
                ref={refInputResNumInput1}
                type="text"
                placeholder="000"
                pattern="\d*"
                value={inputResNumBox[0]}
                onBlur={onBlur}
                onChange={onChangeResNumInput1}
                onPaste={onPaste}
                variant={appearanceResNum.variant}
                triggerblur="false"
                minLength={MAX_LENGTH_RES_NUM[0]}
                maxLength={MAX_LENGTH_RES_NUM[0]}
              />
              {' - '}
              <Input
                required
                id="input-preapproval-nr-box-2"
                ref={refInputResNumInput2}
                type="text"
                placeholder="000"
                pattern="\d*"
                value={inputResNumBox[1]}
                onBlur={onBlur}
                onChange={onChangeResNumInput2}
                onPaste={onPaste}
                variant={appearanceResNum.variant}
                triggerblur="false"
                minLength={MAX_LENGTH_RES_NUM[1]}
                maxLength={MAX_LENGTH_RES_NUM[1]}
              />
              {' - '}
              <Input
                required
                id="input-preapproval-nr-box-3"
                ref={refInputResNumInput3}
                type="text"
                placeholder="000"
                pattern="\d*"
                value={inputResNumBox[2]}
                onBlur={onBlur}
                onChange={onChangeResNumInput3}
                onPaste={onPaste}
                variant={appearanceResNum.variant}
                triggerblur="false"
                minLength={MAX_LENGTH_RES_NUM[2]}
                maxLength={MAX_LENGTH_RES_NUM[2]}
                errorMessage={`Please double check the ${MAX_RESNUM_LENGTH} digit Preapproval number on the letter mailed to you.`}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.fieldset}>
        <h4 className={`heading-gradient-secondary ${styles.number}`}>2</h4>
        <div>
          <label htmlFor="input-zip-code" data-testid="label-zip-code">
            <h5 className={styles.label}>
              Enter Your Zip Code
              {appearanceZip.variant === 'success' && (
                <CheckmarkCircleSVGlement />
              )}
            </h5>
          </label>
          <div className={styles.zipCodeInput}>
            <Input
              required
              data-testid="input-zip-code"
              id="input-zip-code"
              ref={refInputZip}
              type="text"
              placeholder="00000"
              pattern="\d*"
              value={inputZip}
              variant={appearanceZip.variant}
              onChange={onChangeZip}
              minLength={MAX_LENGTH_ZIP}
              maxLength={MAX_LENGTH_ZIP}
              errorMessage="Please enter the zip code on the letter mailed to you."
            />
          </div>
        </div>
      </div>

      <div>
        <RichText data={data?.disclaimer} className={styles.disclaimer} />
        {button}
      </div>
    </form>
  );
}

FormPreloadFromPreapproval.propTypes = {
  data: shape(),
  creative: string,
  dataset: string,
  source: string,
  version: string,
  pageContext: shape(),
};
