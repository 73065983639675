export const formatPhoneNumber = str => {
  const cleaned = `${str}`.replace(/\D/g, '');

  const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);

  if (!match) {
    return str;
  }

  return (
    (match[2] ? '(' : '') +
    match[1] +
    (match[2] ? ') ' : '') +
    match[2] +
    (match[3] ? '-' : '') +
    match[3]
  );
};
