import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';

import { RichText } from 'components/elements/RichText';
import { Spinner } from 'components/elements/Spinner';

import { CheckmarkCircleSVGlement } from 'base/assets/icons';

import { useHashParamsFromPath } from 'base/utils/useParamsFromPath';
import { useLookUpRequest } from 'base/utils/API/requests/useLookUpRequest';

import { useInputAppearance } from '../useInputAppearance';
import { useRequestActions } from '../useRequestActions';
import { useSubmit } from '../useSubmit';

import { Input } from '../Input';

import { useUrlQueryParams } from 'base/utils/useUrlQueryParams';

import styles from './styles.module.css';

const MAX_LENGTH_ZIP = 5;

const useFormValues = location => {
  const { firstName, lastName, zip } = useHashParamsFromPath(location);

  const firstNameState = useState();
  const lastNameState = useState();
  const zipState = useState();

  useEffect(() => {
    if (firstName) {
      const [, setFirstName] = firstNameState;
      setFirstName(firstName);
    }

    if (lastName) {
      const [, setLastName] = lastNameState;
      setLastName(lastName);
    }

    if (zip) {
      const [, setZip] = zipState;
      setZip(zip);
    }
  }, [firstName, lastName, zip]);

  return [firstNameState, lastNameState, zipState];
};

export function FormPreloadFromNames({
  data,
  creative,
  dataset,
  source,
  location,
  pageContext,
}) {
  const lookupRequest = useLookUpRequest();
  const { params } = useUrlQueryParams();

  const [wasChanged, setWasChanged] = useState();
  const [
    [firstName, setFirstName],
    [lastName, setLastName],
    [zip, setZip],
  ] = useFormValues(location);

  const appearanceNames = useInputAppearance();
  const appearanceZip = useInputAppearance();

  const {
    submitRatesRequest,
    lockSubmit,
    unlockSubmit,
    focusSubmit,
    button,
  } = useSubmit({ pageContext });

  const onLookupLoading = () => {
    appearanceNames.setLoading();
    appearanceZip.setLoading();
  };

  const onLookupSuccess = () => {
    appearanceNames.setSuccess();
    appearanceZip.setSuccess();

    focusSubmit();
    unlockSubmit();
    submitRatesRequest();
  };

  const onLookupError = () => {
    appearanceNames.setError();
    appearanceZip.setError();
  };

  useRequestActions({
    loading: { lookup: onLookupLoading },
    success: { lookup: onLookupSuccess },
    error: { lookup: onLookupError },
  });

  const onChangeFirstName = e => {
    appearanceNames.setDefault();
    appearanceZip.setDefault();

    setFirstName(e.target.value);
  };

  const onChangeLastName = e => {
    appearanceNames.setDefault();
    appearanceZip.setDefault();

    setLastName(e.target.value);
  };

  const onChangeZip = e => {
    appearanceZip.setDefault();

    setZip(e.target.value);
  };

  useEffect(() => {
    setWasChanged(true);
    lockSubmit();
  }, [firstName, lastName, zip]);

  useEffect(() => {
    submitLookupRequest();
  }, [zip]);

  const onBlur = e => {
    // prevent lookup request on blur between inputs
    if (e?.relatedTarget?.getAttribute('triggerblur') === 'false') {
      return;
    }

    // prevent lookup request if inputs were not edited
    if (wasChanged === false) {
      return;
    }

    submitLookupRequest();
  };

  const submitLookupRequest = () => {
    if (firstName && lastName && zip?.length === MAX_LENGTH_ZIP) {
      setWasChanged(false);
      lookupRequest.submitRequest({
        firstName,
        lastName,
        zip,
        dataset,
        creative: params.Creative || creative,
        source: params.Source || source,
      });
    }
  };

  return (
    <form
      id="form-get-your-best-rate-names"
      name="form-get-your-best-rate-names"
      className={styles.form}
    >
      <input type="hidden" name="bots" />

      <div className={styles.fieldset}>
        <h4 className={`heading-gradient-secondary ${styles.number}`}>1</h4>
        <div>
          <label htmlFor="get-your-best-rate">
            <h5 className={styles.label}>
              Enter Your First and Last Name
              {appearanceNames.variant === 'loading' && (
                <Spinner variant="xs" theme="secondary" />
              )}
              {(appearanceNames.variant === 'success' ||
                appearanceZip.variant === 'success') && (
                <CheckmarkCircleSVGlement />
              )}
            </h5>
          </label>
          <div className={styles.nameInputs}>
            <Input
              required
              autoFocus
              id="get-your-best-rate"
              type="text"
              placeholder="First Name"
              onBlur={onBlur}
              onChange={onChangeFirstName}
              value={firstName}
              triggerblur="false"
              variant={appearanceNames.variant}
            />
            <Input
              required
              id="input-last-name"
              type="text"
              placeholder="Last Name"
              onBlur={onBlur}
              onChange={onChangeLastName}
              value={lastName}
              triggerblur="false"
              variant={appearanceNames.variant}
            />
          </div>
        </div>
      </div>

      <div className={styles.fieldset}>
        <h4 className={`heading-gradient-secondary ${styles.number}`}>2</h4>
        <div>
          <label htmlFor="input-zip-code">
            <h5 className={styles.label}>
              Enter Your Zip Code
              {appearanceZip.variant === 'success' && (
                <CheckmarkCircleSVGlement />
              )}
            </h5>
          </label>
          <div className={styles.zipCodeInput}>
            <Input
              required
              id="input-zip-code"
              type="text"
              placeholder="00000"
              pattern="\d*"
              minLength={MAX_LENGTH_ZIP}
              maxLength={MAX_LENGTH_ZIP}
              errorMessage="Please provide a 5-digit zip code"
              onBlur={onBlur}
              onChange={onChangeZip}
              value={zip}
              triggerblur="false"
              variant={appearanceZip.variant}
            />
          </div>
        </div>
      </div>

      <div>
        <RichText data={data?.disclaimer} className={styles.disclaimer} />
        {button}
      </div>
    </form>
  );
}

FormPreloadFromNames.propTypes = {
  data: shape(),
  creative: string,
  dataset: string,
  source: string,
  location: shape(),
  pageContext: shape(),
};
