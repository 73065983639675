import { useState } from 'react';

export const useInputAppearance = () => {
  const [variant, setVariant] = useState();

  const setDefault = () => setVariant();
  const setLoading = () => setVariant('loading');
  const setSuccess = () => setVariant('success');
  const setError = () => setVariant('error');

  return { variant, setDefault, setLoading, setSuccess, setError };
};
