import React from 'react';
import { shape } from 'prop-types';
import SbEditable from 'storyblok-react';

import { getSectionId } from 'base/utils/getSectionId';

import { ColumnContent } from './ColumnContent';
import { ColumnForm } from './ColumnForm';
import { BlobSVG } from 'base/assets/decorations';

import styles from './styles.module.css';

export function Form({ data, ...rest }) {
  return (
    <section className="section">
      <SbEditable content={data}>
        <div id={getSectionId(data)} className={styles.wrapper}>
          <ColumnContent data={data.content?.[0]} {...rest} />
          <ColumnForm data={data.form?.[0]} {...rest} />

          <div className={styles.decorations}>
            <img src={BlobSVG} alt="oval" loading="lazy" decoding="async" />
            <img src={BlobSVG} alt="oval" loading="lazy" decoding="async" />
          </div>
        </div>
      </SbEditable>
    </section>
  );
}

Form.propTypes = {
  data: shape(),
};
