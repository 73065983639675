import React from 'react';
import { shape, string } from 'prop-types';
import SbEditable from 'storyblok-react';

import { Form } from 'components/sections/Form';
import { Layout } from 'components/layouts/Layout';
import { ClientValuesResolverContextProvider } from 'base/context/ClientValuesResolverContext';
import { createBloks } from 'base/utils/bloks';

const getHeaderData = data => {
  const header = data?.header?.[0] || [];
  const components = data?.body || [];

  return {
    data: {
      ...header,
      links: components.reduce(
        (acc, { _uid, nav_link_title }) =>
          nav_link_title ? [...acc, { _uid, nav_link_title }] : acc,
        []
      ),
    },
  };
};

const getFooterData = data => ({ data: data?.footer?.[0] });

export function PageLanding({
  location,
  pageContext,
  storyblokMode,
  data,
  ...rest
}) {
  const components = data?.body || [];

  const formData = data?.form?.[0];

  const { creative, dataset, source } = data || {};

  const props = {
    location,
    pageContext,
    storyblokMode,
    creative,
    dataset,
    source,
    ...rest,
  };

  return (
    <ClientValuesResolverContextProvider value={pageContext?.client}>
      <Layout
        {...{
          headerProps: getHeaderData(data),
          footerProps: getFooterData(data),
          ...props,
        }}
      >
        <SbEditable content={data}>
          <div>
            {formData && <Form data={formData} {...props} />}
            {createBloks(components)}
          </div>
        </SbEditable>
      </Layout>
    </ClientValuesResolverContextProvider>
  );
}

PageLanding.propTypes = {
  location: shape(),
  storyblokMode: string,
  data: shape(),
  pageContext: shape(),
};
