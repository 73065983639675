import React from 'react';
import SbEditable from 'storyblok-react';

import { useClientData } from 'base/utils/useClientData';

import { ButtonWithStoryblokStyles } from 'components/elements/ButtonWithStoryblokStyles';
import { Link } from 'components/elements/Links/Link';
import { Button } from 'components/elements/Buttons/Button';

import { HashSVG, PhoneSVG } from 'base/assets/icons';

import styles from './styles.module.css';

export const ContactBox = data => {
  const { phoneNumber, preapprovalNumber, hrefTel } = useClientData();

  const dataButton = data?.button?.[0];

  return (
    <SbEditable content={data}>
      <div className={styles.contactBox}>
        <div>
          <p>{data.left_label ? data.left_label : 'Phone Number'}</p>
          <Link to={hrefTel} variant="with-icon">
            <ButtonWithStoryblokStyles data={dataButton}>
              <Button variant="circle">
                <img
                  src={PhoneSVG}
                  alt="phone-icon"
                  loading="lazy"
                  decoding="async"
                />
              </Button>
            </ButtonWithStoryblokStyles>
            <h3>{phoneNumber}</h3>
          </Link>
        </div>
        <div>
          <p>{data.right_label ? data.right_label : 'PreApproval'}</p>
          <Link to="#" variant="with-icon" disabled>
            <ButtonWithStoryblokStyles data={dataButton}>
              <Button variant="circle" disabled>
                <img
                  src={HashSVG}
                  alt="hash-icon"
                  loading="lazy"
                  decoding="async"
                />
              </Button>
            </ButtonWithStoryblokStyles>
            <h3>{preapprovalNumber}</h3>
          </Link>
        </div>
      </div>
    </SbEditable>
  );
};
