import React from 'react';
import SbEditable from 'storyblok-react';

import { RichText } from 'components/elements/RichText';

import styles from './styles.module.css';
import { shape } from 'prop-types';

export function ColumnContent({ data }) {
  return (
    <>
      <SbEditable content={data}>
        <RichText className={styles.header} data={data?.header} />
      </SbEditable>
      <div>
        <RichText data={data?.description} />

        <div className={styles.imageWrapper}>
          <picture>
            <source
              media="(min-width:576px)"
              srcSet={data?.image_webp_2x?.filename}
              type="image/webp"
            />
            <source srcSet={data?.image_webp?.filename} type="image/webp" />
            <img
              src={data?.image?.filename}
              alt="hero"
              height="250"
              loading="lazy"
              decoding="async"
              className={styles.image}
            />
          </picture>
        </div>
      </div>
    </>
  );
}

ColumnContent.propTypes = {
  data: shape(),
  pageContext: shape(),
};
