import { node, shape } from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { formatPhoneNumber } from 'base/utils/formatPhoneNumber';
import { formatPreApprovalNumber } from 'base/utils/formatPreApprovalNumber';

const ClientValuesResolverContext = createContext(null);

export function ClientValuesResolverContextProvider({
  value: client,
  children,
}) {
  const [FirstName, setFirstName] = useState(''); // placeholder name for easier Storyblok preview
  const [CallNumber, setCallNumber] = useState('(000) 000-0000');
  const [ResNum, setResNum] = useState('');
  const [Zip, setZip] = useState('');

  useEffect(() => {
    if (client?.FirstName) {
      setFirstName(client?.FirstName);
    }

    if (client?.CallNumber) {
      setCallNumber(formatPhoneNumber(client?.CallNumber));
    }

    if (client?.ResNum) {
      setResNum(formatPreApprovalNumber(client?.ResNum));
    }

    if (client?.Zip) {
      setZip(client.Zip);
    }
  }, [client]);

  const resolvePhoneNumber = () => {
    if (client?.CallNumber && client?.ResNum) {
      return `<nobr><a href=tel:${client.CallNumber},,${client.ResNum}>${CallNumber}</a></nobr>`;
    }

    return CallNumber;
  };

  const richTextResolver = html =>
    html
      .replace('first-name', FirstName)
      .replace('phone-number', resolvePhoneNumber())
      .replace('preapproval-number', ResNum)
      .replace('zip', Zip);

  return (
    <ClientValuesResolverContext.Provider
      value={{
        client_raw: client || {},
        client_formatted: { FirstName, CallNumber, ResNum, Zip },
        richTextResolver,
      }}
    >
      {children}
    </ClientValuesResolverContext.Provider>
  );
}

ClientValuesResolverContextProvider.propTypes = {
  value: shape(),
  children: node,
};

export const useClientValuesResolverContext = () =>
  useContext(ClientValuesResolverContext);
