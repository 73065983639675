const OPTIONS = {
  style: 'currency',
  currency: 'USD',
};

export const getNumberFormatter = (options = OPTIONS) =>
  new Intl.NumberFormat('en-US', options);

export const numberFormatter = getNumberFormatter();

export const toNumber = string => string.replace(/\D/g, '');

export const toCurrency = string =>
  numberFormatter.format(string).replace(/^(.*)\D00$/, '$1');

export const isResNumCorrect = (resNum = '') => /^\d{9-10}$/.test(resNum);
export const isZipCorrect = (zip = '') => /^\d{5}$/.test(zip);

export const currencyToNumber = string =>
  Number(string.replace(/[^0-9.-]+/g, ''));
