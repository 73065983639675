import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, func, number, shape, string } from 'prop-types';

import {
  getAreRatesOutdated,
  getLoading,
  getRates,
} from 'base/store/selectors';
import { setOfferIndex, setProductId, setRates } from 'base/store/actions';

import { numberFormatter } from 'base/utils/formatNumber';

import { Tip } from 'components/elements/Tip';
import { Spinner } from 'components/elements/Spinner';
import { RichText } from 'components/elements/RichText';
import { Modal } from 'components/elements/Modals/Modal';
import { Button } from 'components/elements/Buttons/Button';
import { ButtonWithStoryblokStyles } from 'components/elements/ButtonWithStoryblokStyles';

import { ModalDesktopFormLockMyRate } from './ModalDesktopFormLockMyRate';
import { ModalMobileFormLockMyRate } from './ModalMobileFormLockMyRate';
import { SortSVG } from 'base/assets/icons';

import styles from './styles.module.css';

function RowPlaceholder({ data }) {
  return (
    <li className={styles.tableRow}>
      <div className={styles.tableCellProgram}>
        <span>Program</span>
        Example
      </div>
      <div>
        <span>Term</span>30
      </div>
      <div>
        <span>Rate</span>
        5%
      </div>
      <div>
        <span>APR</span>
        5%
      </div>
      <div className={styles.bold}>
        <span>Payment</span>
        $100
      </div>
      <div>
        {!data.hide_fees && (
          <>
            <div className={styles.labelWithTip}>
              Fees
              {data?.discount_fees_tip && <Tip>{data.discount_fees_tip}</Tip>}
            </div>
            $100
          </>
        )}
      </div>
      <ButtonLockMyRate data={data} />
    </li>
  );
}

RowPlaceholder.propTypes = {
  data: shape(),
};

function ButtonLockMyRate({ index, openFormLockMyRateModal, data }) {
  const dispatch = useDispatch();
  const rates = useSelector(getRates);
  const areRatesOutdated = useSelector(getAreRatesOutdated);

  const onClick = () => {
    dispatch(setOfferIndex(index));
    dispatch(setProductId(rates[index].ProductId));

    if (areRatesOutdated) {
      window.location.reload();
    } else {
      openFormLockMyRateModal();
    }
  };

  const dataButtonOffers = data?.offers_button?.[0];

  return (
    <div className={styles.detailsButton}>
      <ButtonWithStoryblokStyles data={dataButtonOffers}>
        <Button onClick={onClick}>
          {dataButtonOffers?.label || 'Lock My Rate Now'}
        </Button>
      </ButtonWithStoryblokStyles>
    </div>
  );
}

ButtonLockMyRate.propTypes = {
  index: number,
  openFormLockMyRateModal: func,
  data: shape(),
};

function Sorter({ label, by, tip, rates }) {
  const [asc, setAsc] = useState(false);
  const [currentSortingKey, setCurrentSortingKey] = useState();
  const dispatch = useDispatch();

  const sortBy = key => {
    const arrayCopy = [...rates];

    arrayCopy.sort((a, b) => {
      if (asc) {
        if (a[key] > b[key]) return -1;
        if (a[key] < b[key]) return 1;
      } else {
        if (a[key] > b[key]) return 1;
        if (a[key] < b[key]) return -1;
      }

      return 0;
    });

    setAsc(!asc);
    setCurrentSortingKey(key);
    dispatch(setRates(arrayCopy));

    return asc;
  };

  const sort = () => sortBy(by);
  const stopPropagation = event => event.stopPropagation();

  return (
    <div
      role="button"
      onClick={sort}
      className={styles.sorter}
      bold={`${currentSortingKey === by}`}
    >
      {label}
      {label && (
        <img
          src={SortSVG}
          alt="sort-icon"
          loading="lazy"
          decoding="async"
          aria-hidden="true"
        />
      )}
      {tip && (
        <div className={styles.tip} onClick={stopPropagation}>
          <Tip>{tip}</Tip>
        </div>
      )}
    </div>
  );
}

Sorter.propTypes = {
  label: string,
  by: string,
  tip: string,
  rates: array,
};

export function List({ data, storyblokMode, location }) {
  const loading = useSelector(getLoading);
  const rates = useSelector(getRates);

  const [
    isFormLockMyRateModalOpened,
    setIsFormLockMyRateModalOpened,
  ] = useState(false);
  const [isOpenedModalTerms, setIsOpenedModalTerms] = useState();

  const openModalTerms = () => setIsOpenedModalTerms(true);
  const closeModalTerms = () => setIsOpenedModalTerms(false);

  const openFormLockMyRateModal = () => {
    setIsFormLockMyRateModalOpened(true);
  };
  const closeFormLockMyRateModal = () => {
    setIsFormLockMyRateModalOpened(false);
  };

  return (
    <>
      <ModalMobileFormLockMyRate
        isOpened={isFormLockMyRateModalOpened}
        closeOverlay={closeFormLockMyRateModal}
        data={data?.lock_my_rate_modal?.[0]}
        openModalTerms={openModalTerms}
      />

      <ModalDesktopFormLockMyRate
        isOpened={isFormLockMyRateModalOpened}
        closeModal={closeFormLockMyRateModal}
        data={data?.lock_my_rate_modal?.[0]}
        openModalTerms={openModalTerms}
      />

      <div>
        {(Object.keys(rates).length !== 0 ||
          location.pathname === '/editor') && (
          <div
            data-testid="sorters"
            className={`${styles.tableHead} ${
              data.hide_fees ? styles.expandedTableRow : ''
            }`}
          >
            <Sorter label="Program" by="Name" rates={rates} />
            <Sorter label="Term" by="AmortTerm" rates={rates} />
            <Sorter label="Rate" by="Rate" rates={rates} />
            <Sorter label="APR" by="Apr" rates={rates} />
            <Sorter
              label="Payment"
              by="TotalPayment"
              tip={data?.payment_tip}
              rates={rates}
            />
            {!data.hide_fees && (
              <Sorter
                label="Fees"
                by="DiscountRebate"
                tip={data?.discount_fees_tip}
                rates={rates}
              />
            )}
          </div>
        )}
        {Object.keys(rates).length !== 0 ? (
          <div>
            {loading.type === 'rates-filters' && loading.isLoading ? (
              <Spinner label="Loading offers..." />
            ) : (
              <ul className={styles.tableBody}>
                {rates.map((mtc, index) => {
                  const {
                    Apr,
                    DiscountRebate,
                    AmortTerm,
                    LoanType,
                    LoanPurpose,
                    OfferId,
                    Rate,
                    TotalPayment,
                  } = mtc;

                  const rt = numberFormatter
                    .format(Rate)
                    .replace(/^\$(\d{1})\.(\d{2})$/g, (match, p1, p2) => {
                      if (p2 === '00') {
                        return `${p1}.0`;
                      }

                      return Rate;
                    });

                  const ap = numberFormatter
                    .format(Apr)
                    .replace(/^\$(\d{1}\.\d{1})\d{1}$/g, (match, p1, p2) => {
                      if (p2 === '00') {
                        return `${p1}.0`;
                      }

                      return Apr;
                    });

                  return (
                    <li
                      data-testid="record"
                      key={`${index}-${OfferId}`}
                      data-index={index}
                      className={`${styles.tableRow} ${
                        data.hide_fees ? styles.expandedTableRow : ''
                      }`}
                    >
                      <div className={styles.tableCellProgram}>
                        <span>Program</span>
                        {LoanType} {LoanPurpose}
                      </div>
                      <div data-testid="Term">
                        <span>Term</span>
                        {AmortTerm.replace(/([0-9/-]+)\s(.*)\D/g, '$1')}
                      </div>
                      <div data-testid="Rate" data-raw={Rate}>
                        <span>Rate</span>
                        {rt}%
                      </div>
                      <div data-testid="APR" data-raw={Apr}>
                        <span>APR</span>
                        {ap}%
                      </div>
                      <div data-testid="Payment" className={styles.bold}>
                        <span>Payment</span>
                        {numberFormatter.format(TotalPayment)}
                      </div>
                      <div>
                        {!data.hide_fees && (
                          <>
                            <div className={styles.labelWithTip}>
                              Fees
                              {data?.discount_fees_tip && (
                                <Tip>{data.discount_fees_tip}</Tip>
                              )}
                            </div>
                            {numberFormatter
                              .format(DiscountRebate)
                              .replace(/^(.*)\D00$/, '$1')}
                          </>
                        )}
                      </div>
                      <ButtonLockMyRate
                        {...{ index, openFormLockMyRateModal, data }}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ) : (
          storyblokMode === 'draft' &&
          location.pathname === '/editor' && (
            <div>
              <ul className={styles.tableBody}>
                <RowPlaceholder data={data} />
                <RowPlaceholder data={data} />
                <RowPlaceholder data={data} />
              </ul>

              {data?.show_modal && (
                <div>
                  <ModalDesktopFormLockMyRate
                    isOpened={true}
                    data={data?.lock_my_rate_modal?.[0]}
                    openModalTerms={openModalTerms}
                  />
                  <ModalMobileFormLockMyRate
                    isOpened={true}
                    data={data?.lock_my_rate_modal?.[0]}
                    openModalTerms={openModalTerms}
                  />
                </div>
              )}
            </div>
          )
        )}
      </div>

      {isOpenedModalTerms && (
        <Modal close={closeModalTerms} headerTop="Terms and Conditions">
          <RichText
            className={styles.contentTermsAndConditions}
            data={data?.lock_my_rate_modal?.[0]?.terms_and_conditions_modal}
          />
        </Modal>
      )}
    </>
  );
}

List.propTypes = {
  data: shape(),
  location: shape(),
  storyblokMode: string,
};
