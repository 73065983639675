const LOAN_TYPE = {
  Refi: 'Refinance',
  Purchase: 'Purchase',
  Cashout: 'Cashout',
};

const PROPERTY_TYPE = {
  SingleFamily: 'Single Family Home',
  MultiFamily: 'Multi Family Home',
  Townhouse: 'Townhouse',
  Condo: 'Condo',
  MobileHome: 'Mobile Home',
};

const DWELLING_TYPE = {
  Primary: 'Primary Residence',
  Secondary: 'Secondary / Vacation',
  Investment: 'Investment Property',
};

const LOAN_POINTS = {
  '0': 'No Points',
  '1': 'Up to 1 Point',
  '2': 'Up to 2 Points',
  '3': 'Up to 3 Points',
};

const keys = {
  LOAN_TERM: 'filter_loan_term',
  LOAN_TYPE: 'filter_loan_type',
  PROPERTY_TYPE: 'filter_property_type',
  DWELLING_TYPE: 'filter_dwelling_type',
  LOAN_POINTS: 'filter_loan_points',
};

const labels = {
  LOAN_TYPE,
  PROPERTY_TYPE,
  DWELLING_TYPE,
  LOAN_POINTS,
};

export const getValues = (data, key) => {
  return (data[keys[key] || ''] || []).sort().map(value => ({
    value,
    label: labels[key][value],
  }));
};
