import React from 'react';
import PropTypes from 'prop-types';

import { RichText } from 'components/elements/RichText';
import { CloseSVG } from 'base/assets/icons';

import styles from './styles.module.css';

export const Modal = ({
  close,
  headerTop,
  headerBottom,
  contentBottom,
  children,
}) => {
  const stopPropagation = event => event.stopPropagation();

  return (
    <div data-testid="modal" className={styles.background} onClick={close}>
      <div className={styles.box} onClick={stopPropagation}>
        <div className={styles.topBar}>
          {typeof headerTop === 'object' && (
            <RichText data={headerTop} className={styles.headerTop} />
          )}
          {typeof headerTop === 'string' && (
            <h3 className={styles.headerTop}>{headerTop}</h3>
          )}
          <div
            data-testid="close-button"
            className={styles.buttonClose}
            onClick={close}
          >
            <img
              src={CloseSVG}
              alt="close-icon"
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>
        <div className={styles.content}>{children}</div>

        {headerBottom && (
          <h3 className={styles.headerBottom}>{headerBottom}</h3>
        )}

        {contentBottom && (
          <p className={styles.contentBottom}>{contentBottom}</p>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  close: PropTypes.func,
  headerTop: PropTypes.any,
  headerBottom: PropTypes.any,
  contentBottom: PropTypes.any,
  children: PropTypes.node,
};
