import React from 'react';
import PropTypes from 'prop-types';

import { PencilSVG } from 'base/assets/icons';

import styles from './styles.module.css';

export const ButtonInputFocus = ({ refInput }) => {
  const onClick = () => {
    refInput?.current?.focus?.();
  };

  return (
    <div className={styles.button} onClick={onClick}>
      <img src={PencilSVG} alt="pencil-icon" loading="lazy" decoding="async" />
    </div>
  );
};

ButtonInputFocus.propTypes = {
  refInput: PropTypes.shape({ current: PropTypes.any }),
};
