import React, { forwardRef, useEffect, useState } from 'react';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';
import { string } from 'prop-types';

import { ButtonInputFocus } from '../ButtonInputFocus';
import { ButtonTogglePasswordVisibility } from './ButtonTogglePasswordVisibility';
import { CloseSVGElement, CheckmarkSVGElement } from 'base/assets/icons';

import styles from './styles.module.css';

const regexTest = regex => value => regex.test(value);

export const PasswordInput = forwardRef(
  ({ name, refNextInputToFocusOnEnterKeyPressed, ...rest }, ref) => {
    const FORM = useFormContext();
    const {
      errors,
      watch,
      formState: { touched },
    } = FORM;

    const [validators, setValidators] = useState({
      min8Chars: false,
      uppercase: false,
      lowercase: false,
      numberChar: false,
      specialChar: false,
      noSpaces: true,
    });

    const value = watch(name) || '';

    useEffect(() => {
      FORM.register(
        { name, type: 'custom' },
        {
          validate: {
            min8Chars: regexTest(/.{8,}/),
            uppercase: regexTest(/[A-Z]/),
            lowercase: regexTest(/[a-z]/),
            numberChar: regexTest(/\d/),
            specialChar: regexTest(/[^\w\s]/),
            noSpaces: regexTest(/^\S*$/),
          },
        }
      );
    }, [FORM, FORM.register, name]);

    const onFocus = event => {
      event.target.select();

      FORM.setFieldsTouched({ ...FORM.fieldsTouched, [name]: true });
    };

    const onChange = event => {
      const { value } = event.target;
      setValidators({
        min8Chars: regexTest(/.{8,}/)(value),
        uppercase: regexTest(/[A-Z]/)(value),
        lowercase: regexTest(/[a-z]/)(value),
        numberChar: regexTest(/\d/)(value),
        specialChar: regexTest(/[^\w\s]/)(value),
        noSpaces: regexTest(/^\S*$/)(value),
      });
      FORM.setValue(name, value);
      FORM.triggerValidation({ name });
    };

    const onKeyPress = event => {
      if (event.key === 'Enter') {
        // eslint-disable-next-line no-unused-expressions
        refNextInputToFocusOnEnterKeyPressed?.current?.focus();
      }
    };

    const getEspecificRule = (condition, rule) => {
      const passwordWasTouched = touched.find(
        error => error === 'lock-my-rate-modal-password'
      );
      return (
        <>
          {condition ? (
            <CheckmarkSVGElement
              className={styles.checkmark}
              style={{
                opacity: passwordWasTouched ? 1 : 0,
              }}
            />
          ) : (
            <CloseSVGElement
              className={styles.error}
              style={{
                opacity: passwordWasTouched ? 1 : 0,
              }}
            />
          )}
          <p
            className={cx({
              [styles.validRule]: condition && passwordWasTouched,
              [styles.invalidRule]: !condition && passwordWasTouched,
            })}
          >
            {rule}
          </p>
        </>
      );
    };

    return (
      <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
        <input
          id={name}
          type="password"
          placeholder="Password"
          className={cx(styles.input, {
            [styles.valid]: value && !errors[name],
          })}
          invalid={!!errors[name]}
          {...{
            ref,
            name,
            onFocus,
            onChange,
            onKeyPress,
            ...rest,
          }}
        />

        <ButtonTogglePasswordVisibility refInput={ref} />
        <ButtonInputFocus refInput={ref} />

        {(!value || (value && errors[name])) && (
          <>
            <div className={styles.rulesList}>
              <div className={styles.dropdownWhiteBox}>
                <div>
                  {getEspecificRule(
                    validators.min8Chars,
                    '8 or more characters'
                  )}
                </div>
                <div>
                  {getEspecificRule(
                    validators.uppercase,
                    'At least one uppercase letter'
                  )}
                </div>
                <div>
                  {getEspecificRule(
                    validators.lowercase,
                    'At least one lowercase letter'
                  )}
                </div>
                <div>
                  {getEspecificRule(
                    validators.numberChar,
                    'At least one number'
                  )}
                </div>
              </div>
              <div className={styles.dropdownWhiteBox}>
                <div>
                  {getEspecificRule(
                    validators.specialChar,
                    'At least one special character (ie !@#$%^&*)'
                  )}
                </div>
                <div>{getEspecificRule(validators.noSpaces, 'No Spaces')}</div>
              </div>
            </div>
          </>
        )}
        {value && !errors[name] && (
          <p className={styles.sublabel}>The password is strong</p>
        )}
      </div>
    );
  }
);

PasswordInput.propTypes = {
  name: string.isRequired,
  refNextInputToFocusOnEnterKeyPressed: React.Ref,
};

PasswordInput.displayName = 'PasswordInput';
