import React from 'react';

import { PageLanding } from 'components/templates/PageLanding';
import { PageOffers } from 'components/templates/PageOffers';
import { Box } from 'components/elements/Box';
import { ContactBox } from 'components/sections/InformationBox/ContactBox';
import { Contact } from 'components/sections/Contact';
import { FAQ } from 'components/sections/FAQ';
import { HeroOffers } from 'components/sections/HeroOffers';
import { InformationBox } from 'components/sections/InformationBox';
import { Offers } from 'components/sections/Offers';
import { RichTextSection } from 'components/sections/RichTextSection';
import { Showcase } from 'components/sections/Showcase';
import { Steps } from 'components/sections/Steps';
import { FlexibleColumn } from 'components/sections/FlexibleColumn';
import { Calendly } from 'components/sections/Calendly';
import { Video } from 'components/elements/Video';
import { Text } from 'components/elements/Text';

const globals = {
  rich_text: RichTextSection,
  box: Box,
  video: Video,
  text: Text,
  flexible_column: FlexibleColumn,
};

const elements = {
  element_information_box_contact: ContactBox,
  calendly: Calendly,
};

const sections = {
  section_contact_form: Contact,
  section_faq: FAQ,
  section_hero_offers: HeroOffers,
  section_information_box: InformationBox,
  section_offers: Offers,
  section_showcase: Showcase,
  section_rich_text: RichTextSection,
  section_steps: Steps,
};

const templates = {
  page_landing: PageLanding,
  page_offers: PageOffers,
};

export const Bloks = {
  ...globals,
  ...elements,
  ...sections,
  ...templates,
};

export const Components = type => {
  if (!Bloks[type]) {
    return () => null;
  }

  return Bloks[type];
};

export const createBlok = (component, props) => {
  return React.createElement(Components(component), props);
};

export const createBloks = (bloks, props) => {
  return bloks.map(blok => {
    return React.createElement(Components(blok.component), {
      data: blok,
      key: blok._uid,
      ...props,
    });
  });
};
