import React from 'react';
import SbEditable from 'storyblok-react';
import PropTypes from 'prop-types';

import { Button } from 'components/elements/Buttons/Button';

import styles from './styles.module.css';

export const Card = ({ data }) => (
  <SbEditable content={data}>
    <div className={styles.card}>
      <Button disabled variant="square" theme="secondary" />
      <h3
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: data?.title }}
      />
    </div>
  </SbEditable>
);

Card.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
  }),
};
