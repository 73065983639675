import React from 'react';
import SbEditable from 'storyblok-react';
import PropTypes from 'prop-types';

import { Button } from 'components/elements/Buttons/Button';
import { RichText } from 'components/elements/RichText';

import { ArrowSVG } from 'base/assets/icons';

import styles from './styles.module.css';

export const Question = ({ questionNr, activeQuestionState, ...data }) => {
  const [activeQuestionNr, setActiveQuestionNr] = activeQuestionState;

  const onChange = () => setActiveQuestionNr(questionNr);

  return (
    <SbEditable content={data}>
      <div data-testid="FAQ-question" className={styles.question}>
        <div className={styles.topBar}>
          <h4 className={`heading-gradient-secondary ${styles.number}`}>
            {questionNr + 1}.
          </h4>
          <h3
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: data?.title }}
          />
        </div>

        <div className={styles.content}>
          <input
            id={`faq-checkbox-input-${questionNr}`}
            name="faq-checkbox-input"
            type="checkbox"
            className={styles.input}
            onChange={onChange}
            checked={activeQuestionNr === questionNr}
          />

          <Button
            theme="secondary"
            variant="sm"
            data-testid="FAQ-question-button"
          >
            <label htmlFor={`faq-checkbox-input-${questionNr}`}>
              <img
                src={ArrowSVG}
                alt="arrow-dropdown"
                loading="lazy"
                decoding="async"
              />
            </label>
          </Button>

          <div className={styles.bodyWrapper}>
            <RichText className={styles.body} data={data.body_short} />
            <RichText
              className={`${styles.body} ${styles.bodyLong}`}
              data={data.body_long}
              data-testid="FAQ-content-long"
            />
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

Question.propTypes = {
  questionNr: PropTypes.number,
  activeQuestionState: PropTypes.any,
};
