import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

export const Video = ({ embed_url }) => {
  return (
    <div className={styles.wrapper}>
      <iframe
        className={styles.iframe}
        frameBorder="0"
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        src={embed_url}
        title="Embeded Resource"
      ></iframe>
    </div>
  );
};

Video.propTypes = {
  embed_url: PropTypes.string,
};
