import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export const SwitchInput = ({
  name,
  value = false,
  values,
  register,
  setValue,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    register({ name, type: 'custom' });

    setIsChecked(value);
    setValue(name, value);
  }, [register]);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
    setValue(name, !isChecked);
  };

  return (
    <div id={name} {...rest} className={styles.wrapper}>
      {values[isChecked.toString()]}
      <div
        className={styles.switch}
        active={isChecked.toString()}
        onClick={toggleSwitch}
      />
    </div>
  );
};

SwitchInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  values: PropTypes.array,
  setValue: PropTypes.func,
  register: PropTypes.func,
};
