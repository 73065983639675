import React, { useEffect, useState } from 'react';
import uid from 'uid';
import PropTypes from 'prop-types';

import { Button } from 'components/elements/Buttons/Button';

import styles from './styles.module.css';

const Option = ({ value, label, isActive, selectedKeys, selectOption }) => {
  const [isSelected, setIsSelected] = useState(isActive);

  const onClick = () => {
    if (!isSelected || selectedKeys.length > 1) {
      setIsSelected(!isSelected);
      selectOption(value);
    }
  };

  return (
    <Button
      type="button"
      variant="select"
      onClick={onClick}
      active={isSelected.toString()}
    >
      {label}
    </Button>
  );
};

export const MultiSelectInput = ({
  name,
  value,
  setValue,
  register,
  required = false,
  options,
  ...rest
}) => {
  const [selectedKeys, setSelectedKeys] = useState(value);

  const addOrRemoveKey = key =>
    selectedKeys.includes(key)
      ? selectedKeys.filter(value => value !== key)
      : [...selectedKeys, key];

  useEffect(() => {
    register({ name, type: 'custom' }, { required });

    setSelectedKeys(value);
    setValue(name, value);
  }, [register]);

  const selectOption = key => {
    const newSelectedKeys = addOrRemoveKey(key);

    setSelectedKeys(newSelectedKeys);
    setValue(name, newSelectedKeys);
  };

  return (
    <div id={name} {...rest}>
      <div className={styles.options}>
        {options.map(({ value, label }) => (
          <Option
            key={`${uid}-${value}`}
            {...{
              value,
              label,
              selectOption,
              selectedKeys,
              isActive: selectedKeys.includes(value),
            }}
          />
        ))}
      </div>
    </div>
  );
};

MultiSelectInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  register: PropTypes.func,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

Option.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  isActive: PropTypes.bool,
  selectedKeys: PropTypes.array,
  selectOption: PropTypes.func,
};
