import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { string, bool, node } from 'prop-types';

import { isInternalLink } from 'base/utils/isInternalLink';
import { isWebLink } from 'base/utils/isWebLink';

import { Button } from 'components/elements/Buttons/Button';

import styles from './styles.module.css';

export const LinkButton = ({
  to = '/',
  theme = 'primary',
  variant,
  disabled,
  children,
  ...rest
}) => {
  const isInternal = isInternalLink(to);

  if (isInternal) {
    return (
      <Button {...{ theme, variant, disabled }}>
        <GatsbyLink to={to} className={styles.link} {...rest}>
          {children}
        </GatsbyLink>
      </Button>
    );
  }

  const target = isWebLink(to) ? '_blank' : '_self';

  return (
    <Button {...{ theme, variant, disabled }}>
      <a
        href={to}
        target={target}
        rel="noopener noreferrer"
        className={styles.link}
        {...rest}
      >
        {children}
      </a>
    </Button>
  );
};

LinkButton.propTypes = {
  to: string,
  theme: string,
  variant: string,
  disabled: bool,
  children: node,
};
