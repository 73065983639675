import { useDispatch, useSelector } from 'react-redux';

import { getOfferId, getRatesParams, getRequestId } from 'base/store/selectors';
import { ratesQuery } from 'base/store/actions';
import { currencyToNumber } from 'base/utils/formatNumber';

export const useFiltersRequest = () => {
  const dispatch = useDispatch();
  const offerId = useSelector(getOfferId);
  const ratesParams = useSelector(getRatesParams);
  const requestId = useSelector(getRequestId);

  const submitForm = name => data => {
    const inputNamesMapped = {
      Zip: data[`${name}-zip`],
      Points: data[`${name}-points`],
      Veteran: data[`${name}-veteran`],
      CashOut: data[`${name}-cashOut`]
        ? currencyToNumber(data[`${name}-cashOut`])
        : '',
      LoanPurpose: data[`${name}-loanPurpose`],
      LoanAmount: currencyToNumber(data[`${name}-loanAmount`]),
      AmortTerm: data[`${name}-amortTerm`],
      PropertyType: data[`${name}-propertyType`],
      DwellingType: data[`${name}-dwellingType`],
      PropertyValue: currencyToNumber(data[`${name}-propertyValue`]),
    };

    dispatch(
      ratesQuery({
        ...ratesParams,
        ...inputNamesMapped,
        OfferId: offerId,
        RequestId: requestId,
        queryType: 'rates-filters',
      })
    );
  };

  return { submitForm };
};
