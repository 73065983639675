import React from 'react';
import PropTypes from 'prop-types';

import { RichText } from 'components/elements/RichText';
import { useStoryblokComponent } from 'base/hooks/useStoryblokComponent';

export const Text = props => {
  const { StoryblokComponent, data } = useStoryblokComponent(props);

  return (
    <StoryblokComponent>
      <RichText
        data={data.text}
        style={data.color?.color && { color: data.color.color }}
      />
    </StoryblokComponent>
  );
};

Text.propTypes = {
  data: {
    text: PropTypes.any,
    color: PropTypes.shape({
      plugin: PropTypes.string,
      color: PropTypes.string,
      _uid: PropTypes.string,
    }),
  },
};
