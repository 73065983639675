/* EXAMPLE OF RESPONSES FOR A WRONG EMAIL ADDRESS (test@gmail.co, test@gmail.io)
  {
    "status": "success",
    "result": "invalid",
    "flags": [
        "free_email_host",
        "role_account",
        "spelling_mistake"
    ],
    "suggested_correction": "test@gmail.com",
    "execution_time": 5383
  }

  {
    "status": "success",
    "result": "unknown",
    "flags": [
        "free_email_host",
        "role_account",
        "has_dns"
    ],
    "suggested_correction": "",
    "execution_time": 360
  }
*/

/* EXAMPLE OF A RESPONSE FOR A CORRECT PHONE NUMBER (daniel@bejamas.io)
  {
    "status": "success",
    "result": "valid",
    "flags": [],
    "suggested_correction": "",
    "execution_time": 129
  }
*/

export const validateEmailAddress = email =>
  fetch('/.netlify/functions/validation-email', {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
    .then(response => response.json())
    .then(
      json =>
        new Promise((resolve, reject) => {
          if (json?.result === 'valid') {
            resolve();
          } else {
            reject(json);
          }
        })
    );
