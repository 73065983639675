import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import isImage from 'is-image';
import { getRatesParams, getResNum } from 'base/store/selectors';

import { formatPhoneNumber } from 'base/utils/formatPhoneNumber';
import { formatPreApprovalNumber } from 'base/utils/formatPreApprovalNumber';

import { useRequestLastUpdateTime } from 'base/utils/useRequestLastUpdateTime';
import { useClientValuesResolverContext } from 'base/context/ClientValuesResolverContext';

import { specialCharacters } from 'base/consts/specialCharacters';

export const useClientData = pageContext => {
  const ratesParams = useSelector(getRatesParams);
  const { client_raw } = useClientValuesResolverContext();
  const resNum = useSelector(getResNum);
  const [params, setParams] = useState({});
  // @TODO: Remove these variables
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [zip, setZip] = useState('00000');
  const [phoneNumber, setPhoneNumber] = useState('(000) 000-0000');
  const [preapprovalNumber, setPreapprovalNumber] = useState('000-000-000');
  const requestLastUpdateTime = useRequestLastUpdateTime();

  useEffect(() => {
    if (ratesParams?.FirstName) {
      setFirstName(ratesParams.FirstName);
    }
    if (ratesParams?.LastName) {
      setLastName(ratesParams.LastName);
    }
    if (ratesParams?.CallNumber) {
      setPhoneNumber(formatPhoneNumber(ratesParams.CallNumber));
    }
    if (ratesParams?.Zip) {
      setZip(ratesParams.Zip);
    }
    if (ratesParams) {
      setParams({ ...ratesParams });
    }
  }, [ratesParams]);

  useEffect(() => {
    if (resNum) {
      setPreapprovalNumber(formatPreApprovalNumber(resNum));
    }
  }, [resNum]);

  const hrefTel = `tel:${phoneNumber}${resNum ? `,,${resNum}` : ''}`;

  const getValueFromRatesParams = value => {
    const keyFromRates = Object.keys(params).find(key => {
      return key === value;
    });

    if (
      params[keyFromRates] &&
      typeof params[keyFromRates] === 'string' &&
      isImage(params[keyFromRates])
    ) {
      params[
        keyFromRates
      ] = `<img src=${params[keyFromRates]} alt="Offer Image" style="width: 100%;"/>`;
    }

    return keyFromRates ? value.replace(value, params[keyFromRates]) : value;
  };

  const deleteSpecialCharacters = text => {
    return text
      .split('')
      .map(letter => (specialCharacters.includes(letter) ? '' : letter))
      .join('');
  };

  const richTextResolver = html => {
    return getValueFromRatesParams(html)
      .replace(
        'first-name',
        pageContext && pageContext.preRendered
          ? client_raw.FirstName
          : firstName
      )
      .replace('time', requestLastUpdateTime)
      .replace(
        'phone-number',
        `<nobr><a href=${hrefTel}>${phoneNumber}</a></nobr>`
      );
  };

  return {
    firstName,
    lastName,
    zip,
    hrefTel,
    phoneNumber,
    preapprovalNumber,
    richTextResolver,
    deleteSpecialCharacters,
  };
};
