import React, { useEffect, useRef } from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';

import { toCurrency, toNumber } from 'base/utils/formatNumber';
import { PencilSVG } from 'base/assets/icons';

import styles from './styles.module.css';

export const CurrencyInput = ({
  name,
  value,
  setValue,
  register,
  required = false,
  disabled = false,
  ...rest
}) => {
  const refInput = useRef();

  const parsedValue = toCurrency(value);

  useEffect(() => {
    if (register) {
      register({ name, type: 'custom' }, { required });
      setValue(name, value);
    }
  }, [register]);

  const onFocus = event => {
    event.target.select();
  };

  const onKeyPress = event => {
    if (!(event.key in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9])) {
      event.preventDefault();
    }
  };

  const onChange = event => {
    const numberValue = toNumber(event.target.value);
    setValue(name, numberValue);
  };

  const onClick = () => {
    refInput?.current?.focus?.();
  };

  return (
    <div className={styles.wrapper}>
      <input
        id={name}
        name={name}
        ref={refInput}
        className={styles.input}
        type="text"
        maxLength="11"
        inputMode="numeric"
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        onChange={onChange}
        value={parsedValue}
        disabled={disabled}
        {...rest}
      />
      {!disabled && (
        <div className={styles.pencil} onClick={onClick}>
          <img
            src={PencilSVG}
            alt="pencil-icon"
            loading="lazy"
            decoding="async"
            data-testid="pencil-icon"
          />
        </div>
      )}
    </div>
  );
};

CurrencyInput.propTypes = {
  name: string,
  value: oneOfType([string, number]),
  setValue: func,
  register: func,
  required: bool,
  disabled: bool,
};
