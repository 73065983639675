import React from 'react';
import { bool, func, shape } from 'prop-types';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  getExpiration,
  getLoading,
  getRatesParams,
} from 'base/store/selectors';
import { setAreRatesOutdated } from 'base/store/actions';

import { useFiltersRequest } from 'base/utils/API/requests/useFiltersRequest';
import { toCurrency } from 'base/utils/formatNumber';

import { FilterSVG } from 'base/assets/icons';

import { ButtonWithStoryblokStyles } from 'components/elements/ButtonWithStoryblokStyles';
import { FormFilterOffers } from 'components/sections/Forms/FormFilterOffers';
import { Link } from 'components/elements/Links/Link';
import { MobileOverlay } from 'components/elements/Modals/MobileOverlay';
import { ButtonSubmit } from 'components/elements/Buttons/ButtonSubmit';

import styles from './styles.module.css';

const FORM_NAME = 'filters-mobile-overlay';

export function OverlayMobileFormFilterOffers({
  isOpened,
  closeOverlay,
  data,
}) {
  const dispatch = useDispatch();
  const expiration = useSelector(getExpiration);
  const loading = useSelector(getLoading);
  const ratesParams = useSelector(getRatesParams);
  const { submitForm } = useFiltersRequest();
  const { handleSubmit, register, setValue, getValues } = useForm({
    defaultValues: {
      [`${FORM_NAME}-loanAmount`]:
        ratesParams &&
        ratesParams.LoanAmount &&
        toCurrency(ratesParams.LoanAmount),
      [`${FORM_NAME}-propertyValue`]:
        ratesParams &&
        ratesParams.PropertyValue &&
        toCurrency(ratesParams.PropertyValue),
    },
  });

  const onSubmit = handleSubmit(values => {
    const diff = differenceInMinutes(expiration.timeout, new Date());

    dispatch(setAreRatesOutdated(diff > 15));
    submitForm(FORM_NAME)(values);
    closeOverlay();
  });

  const dataButtonFilter = data?.filter_button?.[0];

  return (
    <MobileOverlay
      label={
        <Link variant="flex" role="button" onClick={closeOverlay}>
          <div>Filter Offers</div>
          <img
            src={FilterSVG}
            alt="filter-icon"
            loading="lazy"
            decoding="async"
          />
        </Link>
      }
      {...{ isOpened, closeOverlay, withHeader: true }}
    >
      <form
        data-testid="mobile-filters"
        onSubmit={onSubmit}
        className={styles.form}
      >
        <FormFilterOffers
          {...{
            name: FORM_NAME,
            register,
            setValue,
            getValues,
            data,
            ratesParams,
          }}
        />

        <div
          data-testid="submit-filter-mobile"
          className={styles.buttonSubmit}
          is_opened={isOpened.toString()}
        >
          <ButtonWithStoryblokStyles data={dataButtonFilter}>
            <ButtonSubmit
              loading={loading.isLoading}
              label={dataButtonFilter?.label || 'Update Offers'}
              labelWhileLoading="Checking Offers"
            />
          </ButtonWithStoryblokStyles>
        </div>
      </form>
    </MobileOverlay>
  );
}

OverlayMobileFormFilterOffers.propTypes = {
  isOpened: bool,
  closeOverlay: func,
  data: shape(),
};
