import React, { useEffect, useState } from 'react';
import { shape } from 'prop-types';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  getError,
  getExpiration,
  getLoading,
  getRates,
  getSuccess,
  getRatesParams,
} from 'base/store/selectors';
import { setAreRatesOutdated, setError } from 'base/store/actions';

import { useFiltersRequest } from 'base/utils/API/requests/useFiltersRequest';
import { useClientData } from 'base/utils/useClientData';
import { toCurrency } from 'base/utils/formatNumber';

import { ButtonWithStoryblokStyles } from 'components/elements//ButtonWithStoryblokStyles';
import { FormFilterOffers } from '../FormFilterOffers';
import { Modal } from 'components/elements/Modals/Modal';
import { ButtonSubmit } from 'components/elements/Buttons/ButtonSubmit';

import styles from './styles.module.css';

const FORM_NAME = 'rates';

export function FormGetOffers({ data }) {
  const dispatch = useDispatch();
  const error = useSelector(getError);
  const expiration = useSelector(getExpiration);
  const loading = useSelector(getLoading);
  const rates = useSelector(getRates);
  const success = useSelector(getSuccess);
  const ratesParams = useSelector(getRatesParams);

  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);
  const [isNoRatesFoundModalOpened, setIsNoRatesFoundModalOpened] = useState(
    false
  );

  const { submitForm } = useFiltersRequest();
  const { handleSubmit, register, setValue, getValues } = useForm({
    defaultValues: {
      [`${FORM_NAME}-loanAmount`]:
        ratesParams &&
        ratesParams.LoanAmount &&
        toCurrency(ratesParams.LoanAmount),
      [`${FORM_NAME}-propertyValue`]:
        ratesParams &&
        ratesParams.PropertyValue &&
        toCurrency(ratesParams.PropertyValue),
    },
  });
  const { phoneNumber, hrefTel } = useClientData();

  const closeErrorModal = () => {
    setIsErrorModalOpened(false);
    dispatch(
      setError({
        type: '',
        isError: false,
      })
    );
  };
  const closeNoRatesFoundModal = () => setIsNoRatesFoundModalOpened(false);

  const onSubmit = values => {
    const diff = differenceInMinutes(expiration.timeout, new Date());
    dispatch(setAreRatesOutdated(diff > 15));
    submitForm(FORM_NAME)(values);
  };

  useEffect(() => {
    const noRates = [
      success.type === 'rates',
      success.didSucceed,
      rates.length === 0,
    ].every(val => val);

    if (error.type === 'rates' && error.isError) {
      setIsErrorModalOpened(true);
    }
    if (noRates) {
      setIsNoRatesFoundModalOpened(true);
    }
  }, [error, rates, success]);

  const dataButtonFilter = data?.filter_button?.[0];

  return (
    <>
      {isErrorModalOpened && (
        <Modal close={closeErrorModal}>
          <h2>Looks like there was a problem getting your Rates.</h2>
          <p>
            Give us a call at <a href={hrefTel}>{phoneNumber}</a> so we can find
            the best offer for you!
          </p>
        </Modal>
      )}

      {isNoRatesFoundModalOpened && (
        <Modal close={closeNoRatesFoundModal}>
          <h2 data-testid="no-rates-modal">Looks like there are no offers.</h2>
          <p>
            Try changing a few of the options to check our other offers
            available for you.
          </p>
        </Modal>
      )}

      <form
        data-testid="desktop-filters"
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
      >
        <FormFilterOffers
          {...{
            name: FORM_NAME,
            register,
            setValue,
            getValues,
            data,
            ratesParams,
          }}
        />

        <div className={styles.button}>
          <ButtonWithStoryblokStyles data={dataButtonFilter}>
            <ButtonSubmit
              data-testid="submit-filter"
              loading={loading.isLoading}
              label={dataButtonFilter?.label || 'Update Offers'}
              labelWhileLoading="Checking Offers"
            />
          </ButtonWithStoryblokStyles>
        </div>
      </form>
    </>
  );
}

FormGetOffers.propTypes = {
  data: shape(),
};
