import { useEffect, useState } from 'react';

export function useUrlQueryParams() {
  const [params, setParams] = useState({});

  const getParams = location => {
    const paramsFromUrl = new URLSearchParams(location);
    let paramsToSet = {};
    for (var key of paramsFromUrl.keys()) {
      paramsToSet[key] = paramsFromUrl.get(key);
    }
    return paramsToSet;
  };

  useEffect(() => {
    const paramsToSet = getParams(window.location.search);
    setParams(paramsToSet);
  }, []);

  return { params, getParams };
}
