import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import { useRatesRequest } from 'base/utils/API/requests/useRatesRequest';
import { useResNumRatesRequest } from 'base/utils/API/requests/useResNumRatesRequest';
import { formatPreApprovalNumber } from 'base/utils/formatPreApprovalNumber';

import { ButtonSubmit } from 'components/elements/Buttons/ButtonSubmit';
import { LinkButton } from 'components/elements/Links/LinkButton';
import { RichText } from 'components/elements/RichText';

import { useClientValuesResolverContext } from 'base/context/ClientValuesResolverContext';
import { useRequestActions } from '../useRequestActions';
import { getOfferWeb, getRatesParams } from 'base/store/selectors';

import styles from './styles.module.css';

export function FormPreloadFromURLButtons({
  data,
  pageContext,
  location,
  creative,
  dataset,
  source,
}) {
  const ratesRequest = useRatesRequest();
  const offerWeb = useSelector(getOfferWeb);
  const rateParams = useSelector(getRatesParams);
  const { client_raw } = useClientValuesResolverContext();
  const resNumRatesRequest = useResNumRatesRequest();
  const [clientData, setClientData] = useState({
    ResNum: '',
    ResNumFormatted: '',
    Zip: '',
    CallNumber: '800',
  });
  const [phoneNumber, setPhoneNumber] = useState();

  useRequestActions({
    success: { resNumRates: ratesRequest.submitRequest },
    pageContext,
  });

  const onSubmit = e => {
    e.preventDefault();
    navigate(
      pageContext.offerWebToPathNameMap[
        pageContext.preRendered ? client_raw.OfferWeb : offerWeb
      ]
    );
  };

  const getSmsHref = () => {
    //TODO: sms body
    const PHONE_NUMBER = '800708318';
    return `sms:${PHONE_NUMBER};?&body=${encodeURIComponent(
      `Your Preapproval Number: ${clientData.ResNumFormatted}, Your Zip Code: ${clientData.Zip}`
    )}`;
  };

  const parseHash = hash => {
    const hashSplitted = hash.replace('#', '').split('-');
    setClientData({
      ...clientData,
      ResNum: hashSplitted[0],
      ResNumFormatted: `${hashSplitted[0].slice(
        0,
        3
      )} - ${hashSplitted[0].slice(3, 6)} - ${hashSplitted[0].slice(
        6,
        hashSplitted[0].length
      )}`,
      Zip: hashSplitted[1],
    });
    return { resNum: hashSplitted[0], Zip: hashSplitted[1] };
  };

  useEffect(() => {
    if (location.hash) {
      const { resNum } = parseHash(location.hash);
      const prefix =
        process.env.NODE_ENV !== 'prod' ? `${pageContext.envContext}-` : '';
      resNumRatesRequest.submitRequest({
        resNum,
        creative: `${prefix}${creative}`,
        dataset,
        source: `${prefix}${source}`,
      });
    } else if (pageContext.preRendered) {
      resNumRatesRequest.submitRequest({ resNum: client_raw.ResNum });
    }
  }, []);

  useEffect(() => {
    setPhoneNumber(rateParams.CallNumber);
  }, [rateParams]);

  const callUsButton = data.buttons_props?.find(
    buttonProps => buttonProps.type === 'call-us'
  );
  const submitButton = data.buttons_props?.find(
    buttonProps => buttonProps.type === 'submit'
  );
  const smsButton = data.buttons_props?.find(
    buttonProps => buttonProps.type === 'sms'
  );

  return (
    <form
      id="form-get-your-best-rate"
      name="form-get-your-best-rate"
      className={styles.form}
      onSubmit={onSubmit}
    >
      <input type="hidden" name="bots" />

      <div className={styles.description}>
        <p id="p-form-get-your-best-rate-preapproval">
          {`Your Preapproval Number: ${
            pageContext.preRendered
              ? formatPreApprovalNumber(client_raw.ResNum)
              : clientData.ResNumFormatted
          }`}
        </p>
        <p id="p-form-get-your-best-rate-zip">{`Your Zip Code: ${
          pageContext.preRendered ? client_raw.Zip : clientData.Zip
        }`}</p>
      </div>

      {callUsButton && (
        <LinkButton
          id="button-call-form-get-your-best-rate"
          to={`tel:${phoneNumber},,${clientData.ResNum}`}
        >
          {callUsButton.label}
        </LinkButton>
      )}

      {submitButton && (
        <ButtonSubmit
          id="button-submit-form-get-your-best-rate"
          label={submitButton.label}
        />
      )}

      {smsButton && (
        <LinkButton id="button-sms-form-get-your-best-rate" to={getSmsHref()}>
          {smsButton.label}
        </LinkButton>
      )}

      <RichText data={data?.disclaimer} className={styles.disclaimer} />
    </form>
  );
}

FormPreloadFromURLButtons.propTypes = {
  data: shape(),
  pageContext: shape(),
  location: shape(),
  creative: string,
  dataset: string,
  source: string,
};
