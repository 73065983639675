import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getError, getLoading, getSuccess } from 'base/store/selectors';

export function useRequestActions(actions = {}) {
  const loading = useSelector(getLoading);
  const success = useSelector(getSuccess);
  const error = useSelector(getError);

  const handleActions = (actions = {}, type) =>
    Object.keys(actions).forEach(key => key === type && actions[key]());

  useEffect(() => {
    if (loading.isLoading) {
      handleActions(actions.loading, loading.type);
    }

    if (success.didSucceed) {
      handleActions(actions.success, success.type);
    }

    if (error.isError) {
      handleActions(actions.error, error.type);
    }
  }, [error, loading, success]);
}
