import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import SbEditable from 'storyblok-react';
import { useSelector } from 'react-redux';

import { removeEmpty } from 'base/utils/removeEmpty';

import { getRatesParams, getResNum } from 'base/store/selectors';

import { LayoutWithFilters } from 'components/layouts/LayoutWithFilters';
import { ClientValuesResolverContextProvider } from 'base/context/ClientValuesResolverContext';
import { createBloks } from 'base/utils/bloks';

const getHeaderData = data => {
  const header = data?.header?.[0] || [];
  const components = data?.body || [];

  const offersSectionData = components.find(
    ({ component }) => component === 'section_offers'
  );

  return {
    data: {
      logo: data?.logo,
      ...header,
      ...offersSectionData,
    },
  };
};

const getFooterData = data => ({ data: data?.footer?.[0] });

const getPageOffersStyleOverride = data => {
  const colorPrimary = data?.primary_color?.color;
  const colorSecondary = data?.secondary_color?.color;

  const cssVariables = {
    '--color-primary': colorPrimary,
    '--color-background-light': colorSecondary,

    '--background-primary': colorPrimary,
    '--background-primary-active': colorSecondary,
    '--shadow-primary': colorPrimary && `0px 10px 35px ${colorPrimary}`,
    '--shadow-primary-active':
      colorSecondary && `0px 10px 35px ${colorSecondary}`,

    '--color-font-heading': data?.color_font_heading?.color,
    '--color-font-body': data?.color_font_body?.color,
  };

  return removeEmpty(cssVariables);
};

const useUrlReplace = () => {
  const resNum = useSelector(getResNum);
  const ratesParams = useSelector(getRatesParams);

  useEffect(() => {
    if (resNum && ratesParams.Zip) {
      history.pushState(
        {},
        'Your Results',
        `/personalized-offers#${resNum}-${ratesParams.Zip}`
      );
    }
  }, [resNum, ratesParams.Zip]);
};

export function PageOffers({
  location,
  pageContext,
  storyblokMode,
  data,
  ...rest
}) {
  useUrlReplace();

  const components = data?.body || [];

  const style = getPageOffersStyleOverride(data);

  const props = {
    location,
    pageContext,
    storyblokMode,
    style,
    ...rest,
  };

  return (
    <ClientValuesResolverContextProvider value={null}>
      <LayoutWithFilters
        {...{
          location,
          headerProps: getHeaderData(data),
          footerProps: getFooterData(data),
          style,
        }}
      >
        <SbEditable content={data}>
          <div style={style}>{createBloks(components, props)}</div>
        </SbEditable>
      </LayoutWithFilters>
    </ClientValuesResolverContextProvider>
  );
}

PageOffers.propTypes = {
  location: shape(),
  storyblokMode: string,
  data: shape(),
  pageContext: shape(),
};
