import React from 'react';
import SbEditable from 'storyblok-react';
import PropTypes from 'prop-types';

import { LinkButton } from 'components/elements/Links/LinkButton';

import styles from './styles.module.css';

export const ContactLink = ({ data }) => {
  const content = (
    <h3
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: data?.content }}
    />
  );

  return (
    <SbEditable content={data}>
      <div className={styles.contactLink}>
        <LinkButton
          to={data?.link}
          variant="sm"
          theme="secondary"
          disabled={!data?.link}
        >
          <img
            src={data?.icon?.filename}
            alt="contact-icon"
            loading="lazy"
            decoding="async"
          />
        </LinkButton>
        <div>
          <p className={styles.label}>{data?.label}</p>
          {data?.link ? <a href={data?.link}>{content}</a> : content}
        </div>
      </div>
    </SbEditable>
  );
};

ContactLink.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.any,
    link: PropTypes.string,
    icon: PropTypes.shape({
      filename: PropTypes.string,
    }),
    label: PropTypes.string,
  }),
};
