import React, { useEffect, useState } from 'react';
import SbEditable from 'storyblok-react';
import { Link as GatsbyLink } from 'gatsby';
import slugify from 'slugify';

import { formatPhoneNumber } from 'base/utils/formatPhoneNumber';

import { Link } from 'components/elements/Links/Link';
import { Button } from 'components/elements/Buttons/Button';
import { HamburgerSVG, LogoSVG, PhoneSVG } from 'base/assets/icons';

import styles from './styles.module.css';
import { shape } from 'prop-types';

const PHONE_NR_DEFAULT = '8004581605';

export const Header = ({ data, location }) => {
  const [resNum, setResNum] = useState();

  const parseHash = hash => {
    const hashSplitted = hash.replace('#', '').split('-');
    return { resNum: hashSplitted[0], Zip: hashSplitted[1] };
  };

  useEffect(() => {
    if (location && location.hash) {
      const { resNum: resNumParsed } = parseHash(location.hash);
      setResNum(resNumParsed);
    }
  }, [location]);

  const LinkPhone = (
    <Link
      to={`tel:${data?.phone_number || PHONE_NR_DEFAULT}${
        resNum ? `,,${resNum}` : ''
      }`}
      variant="with-icon"
    >
      {formatPhoneNumber(data?.phone_number || PHONE_NR_DEFAULT)}
      <Button variant="xs">
        <img src={PhoneSVG} alt="phone-icon" loading="lazy" decoding="async" />
      </Button>
    </Link>
  );

  const links = data?.links || [];

  return (
    <section className="section-wide">
      <SbEditable content={data}>
        <header data-testid="header" className={styles.header}>
          <div className={styles.headerLogo}>
            <GatsbyLink to="/">
              <img
                src={data?.logo?.filename || LogoSVG}
                alt="youarepreapproved-logo"
                loading="lazy"
                decoding="async"
              />
            </GatsbyLink>
          </div>

          <div className={styles.links} data-testid="header-links">
            {links.map(({ _uid, nav_link_title }) => (
              <Link
                key={_uid}
                to={`#${slugify(nav_link_title, { lower: true })}`}
                variant="with-underline"
              >
                {nav_link_title}
              </Link>
            ))}
          </div>

          <div data-testid="phone-link" className={styles.links}>
            {LinkPhone}
          </div>

          <div className={styles.dropdownMobile}>
            <input type="checkbox" id="input-checkbox-dropdown-mobile" />
            <label
              data-testid="mobile-menu"
              htmlFor="input-checkbox-dropdown-mobile"
            >
              <img
                src={HamburgerSVG}
                alt="dropdown-menu-button"
                loading="lazy"
                decoding="async"
              />
            </label>

            <ul
              data-testid="header-links-mobile"
              className={styles.linksMobile}
            >
              {links.map(({ _uid, nav_link_title }) => (
                <li key={_uid}>
                  <Link to={`#${slugify(nav_link_title, { lower: true })}`}>
                    {nav_link_title}
                  </Link>
                </li>
              ))}
              <li>{LinkPhone}</li>
            </ul>
          </div>
        </header>
      </SbEditable>
    </section>
  );
};

Header.propTypes = {
  data: shape(),
  location: shape(),
};
