import React, { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { ButtonInputFocus } from '../ButtonInputFocus';
import { toNumber } from 'base/utils/formatNumber';
import { formatPhoneNumber } from 'base/utils/formatPhoneNumber';

import styles from './styles.module.css';

export const PhoneNumberInput = forwardRef(
  ({ name, value, refNextInputToFocusOnEnterKeyPressed, ...rest }, ref) => {
    const defaultValue = formatPhoneNumber(value);

    const FORM = useFormContext();

    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
      FORM.register(
        { name, type: 'custom' },
        {
          required: 'Please provide a phone number',
          pattern: {
            value: /\d{10}/,
            message: 'Please provide valid phone number',
          },
        }
      );

      FORM.setValue(name, value);
    }, [FORM.register]);

    const onBlur = async () => {
      const isValid = await FORM.triggerValidation({ name });

      setIsInvalid(!isValid);
    };

    const onFocus = event => {
      event.target.select();

      FORM.setFieldsTouched({ ...FORM.fieldsTouched, [name]: true });
    };

    const onChange = event => {
      const numberValue = toNumber(event.target.value);

      event.target.value = formatPhoneNumber(numberValue);
      FORM.setValue(name, numberValue);

      if (isInvalid) {
        onBlur();
      }
    };

    const onKeyPress = event => {
      if (!(event.key in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9])) {
        event.preventDefault();
      }

      if (event.key === 'Enter') {
        // eslint-disable-next-line no-unused-expressions
        refNextInputToFocusOnEnterKeyPressed?.current?.focus();
      }
    };

    return (
      <div className={styles.wrapper}>
        <input
          id={name}
          type="tel"
          inputMode="tel"
          placeholder="Phone Number"
          maxLength="14"
          pattern="\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}"
          data-value={value}
          className={styles.input}
          invalid={isInvalid.toString()}
          {...{
            ref,
            name,
            onBlur,
            onFocus,
            onChange,
            onKeyPress,
            defaultValue,
            ...rest,
          }}
        />

        <ButtonInputFocus refInput={ref} />

        <div className={styles.errorMessage}>
          {FORM.errors?.[name]?.message}
        </div>
      </div>
    );
  }
);

PhoneNumberInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  refNextInputToFocusOnEnterKeyPressed: PropTypes.shape({
    current: PropTypes.any,
  }),
};

PhoneNumberInput.displayName = 'PhoneNumberInput';
