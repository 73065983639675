import React, { useEffect, useRef, useState } from 'react';
import { shape, string } from 'prop-types';
import { useSelector } from 'react-redux';

import { getRatesParams } from 'base/store/selectors';

import { toNumber } from 'base/utils/formatNumber';
import { useResNumRatesRequest } from 'base/utils/API/requests/useResNumRatesRequest';
import { formatPhoneNumber } from 'base/utils/formatPhoneNumber';

import { useInputAppearance } from '../useInputAppearance';
import { useRequestActions } from '../useRequestActions';
import { useSubmit } from '../useSubmit';
import { validateEmailAddress } from './validateEmailAddress';
import { validatePhoneNumber } from './validatePhoneNumber';

import { RichText } from 'components/elements/RichText';
import { Input } from '../Input';

import { CheckmarkCircleSVGlement } from 'base/assets/icons';

import { useUrlQueryParams } from 'base/utils/useUrlQueryParams';

import styles from './styles.module.css';

const MAX_LENGTH_RES_NUM = 10;
const MAX_LENGTH_RES_NUM_PER_INPUT = [3, 3, 4];
const MAX_LENGTH_ZIP = 5;
const MAX_LENGTH_PHONE = 10;

export function FormPreloadFromPreapproval10({
  data,
  creative,
  dataset,
  source,
  pageContext,
}) {
  const { params: paramsFromUrl } = useUrlQueryParams();

  const resNumRatesRequest = useResNumRatesRequest();
  const ratesParams = useSelector(getRatesParams);

  const refInputResNumInput1 = useRef();
  const refInputResNumInput2 = useRef();
  const refInputResNumInput3 = useRef();
  const refInputZip = useRef();
  const refInputPhone = useRef();
  const refInputEmail = useRef();

  const [inputResNumBox, setInputResNumBox] = useState([null, null, null]);
  const [inputZip, setInputZip] = useState();
  const [inputPhone, setInputPhone] = useState();
  const [wasEmailChanged, setWasEmailChanged] = useState();

  const appearanceResNum = useInputAppearance();
  const appearanceZip = useInputAppearance();
  const appearancePhone = useInputAppearance();
  const appearanceEmail = useInputAppearance();

  const {
    submitRatesRequest,
    lockSubmit,
    unlockSubmit,
    focusSubmit,
    button,
  } = useSubmit({ pageContext });

  useRequestActions({
    loading: { resNumRates: () => appearanceResNum.setLoading() },
    success: {
      resNumRates: () => {
        appearanceResNum.setSuccess();
        appearanceZip.setDefault();
        checkZip(inputZip);
      },
    },
    error: { resNumRates: () => appearanceResNum.setError() },
  });

  // @TODO: Use Regexp
  const isNoRates = ratesParams.ApiMessage?.toLowerCase().includes('no rates');

  const checkZip = value => {
    if (
      value?.length === MAX_LENGTH_ZIP &&
      appearanceResNum.variant !== 'loading' &&
      inputResNumBox.join('').length === MAX_LENGTH_RES_NUM
    ) {
      if (value === ratesParams.Zip) {
        appearanceResNum.setSuccess();
        appearanceZip.setSuccess();

        if (!isNoRates) {
          submitRatesRequest();
        }
      } else {
        appearanceZip.setError();
      }
    } else {
      appearanceZip.setDefault();
    }
  };

  const focusNextInput = (value, maxLength, nr) => {
    if (value?.length === maxLength) {
      [
        refInputResNumInput1,
        refInputResNumInput2,
        refInputResNumInput3,
        refInputZip,
        refInputPhone,
        refInputEmail,
      ][nr + 1]?.current.focus();
    }
  };

  const onChangeResNumInput = (e, nr) => {
    lockSubmit();
    appearanceResNum.setDefault();
    appearanceZip.setDefault();

    const value = e.target.value;
    let copy = [...inputResNumBox];
    copy[nr] = value;
    setInputResNumBox(copy);
    setInputZip('');
    focusNextInput(value, MAX_LENGTH_RES_NUM_PER_INPUT[nr], nr);
  };

  const onChangeZip = e => {
    lockSubmit();
    appearanceZip.setDefault();

    const value = e.target.value;
    setInputZip(value);
    checkZip(value);
    focusNextInput(value, MAX_LENGTH_ZIP, 3);
  };

  const onChangePhone = e => {
    appearancePhone.setDefault();

    const value = toNumber(e.target.value);
    const valueFormatted = formatPhoneNumber(value);
    setInputPhone(valueFormatted);
    focusNextInput(value, MAX_LENGTH_PHONE, 4);
    submitPhoneValidationRequest(value);
  };

  const submitPhoneValidationRequest = phoneNumber => {
    if (phoneNumber.length === MAX_LENGTH_PHONE) {
      appearancePhone.setLoading();

      validatePhoneNumber(phoneNumber)
        .then(() => {
          appearancePhone.setSuccess();
          refInputEmail?.current?.focus();
        })
        .catch(error => {
          appearancePhone.setError();
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  };

  const onChangeEmail = () => {
    setWasEmailChanged(true);
    appearanceEmail.setDefault();
  };

  const onBlurEmail = e => {
    const value = e.target.value;

    if (wasEmailChanged) {
      setWasEmailChanged(false);

      appearanceEmail.setLoading();

      validateEmailAddress(value)
        .then(() => {
          appearanceEmail.setSuccess();
          focusSubmit();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          appearanceEmail.setError();
        });
    }
  };

  const onPaste = e => {
    e.preventDefault();
    appearanceResNum.setDefault();
    appearanceZip.setDefault();

    const pastedResNum = (e.clipboardData || window.clipboardData)
      .getData('text')
      .replace(/\D/g, '')
      .slice(0, MAX_LENGTH_RES_NUM);
    setInputResNumBoxFromString(pastedResNum);
    setInputZip('');

    if (pastedResNum.length < 3) {
      refInputResNumInput1?.current.focus();
    } else if (pastedResNum.length < 6) {
      refInputResNumInput2?.current.focus();
    } else if (pastedResNum.length < MAX_LENGTH_RES_NUM) {
      refInputResNumInput3?.current.focus();
    } else {
      refInputZip?.current.focus();
      submitResNumRatesRequest(pastedResNum);
    }
  };

  const setInputResNumBoxFromString = stringValue => {
    let copy = [...inputResNumBox];
    copy[0] = stringValue.slice(0, 3);
    copy[1] = stringValue.slice(3, 6);
    copy[2] = stringValue.slice(6, MAX_LENGTH_RES_NUM);
    setInputResNumBox(copy);
  };

  const submitResNumRatesRequest = value => {
    const resNum = value;

    if (resNum.length === MAX_LENGTH_RES_NUM) {
      resNumRatesRequest.submitRequest({ resNum, creative, dataset, source });
    }
  };

  const setDefaultValuesFromUrl = (params = {}) => {
    if (params.preApprovalNumber && params.zipCode) {
      setInputResNumBoxFromString(params.preApprovalNumber);
      setInputZip(params.zipCode);
      focusNextInput(params.zipCode, MAX_LENGTH_ZIP, 3);
    } else if (params.zipCode) {
      setInputZip(params.zipCode);
    }
  };

  // UseEffects
  useEffect(() => {
    appearanceResNum.setDefault();
    appearanceZip.setDefault();
  }, []);

  useEffect(() => {
    if (paramsFromUrl) {
      setDefaultValuesFromUrl(paramsFromUrl);
    }
  }, [paramsFromUrl]);

  useEffect(() => {
    if (appearanceResNum.variant === 'success') {
      checkZip(inputZip);
    }
  }, [appearanceResNum.variant]);

  useEffect(() => {
    const preApprovalNumber = [...inputResNumBox].join('');
    if (preApprovalNumber.length === MAX_LENGTH_RES_NUM) {
      submitResNumRatesRequest(preApprovalNumber);
    }
  }, [inputResNumBox]);

  useEffect(() => {
    if (
      appearanceResNum.variant === 'success' &&
      appearanceZip.variant === 'success'
    ) {
      unlockSubmit();
    }
  }, [appearanceResNum, appearanceZip, appearancePhone, appearanceEmail]);

  return (
    <form
      id="form-get-your-best-rate"
      name="form-get-your-best-rate"
      className={styles.form}
    >
      <input type="hidden" name="bots" />

      <div className={styles.fieldset}>
        <h4 className={`heading-gradient-secondary ${styles.number}`}>1</h4>
        <div>
          <label htmlFor="get-your-best-rate">
            <h5 className={styles.label}>
              Enter Your PreApproval Number
              {appearanceResNum.variant === 'success' && (
                <CheckmarkCircleSVGlement />
              )}
            </h5>
          </label>
          <div className={styles.preApprovalNumberInputs}>
            <Input
              required
              autoFocus
              id="get-your-best-rate"
              ref={refInputResNumInput1}
              type="text"
              placeholder="000"
              pattern="\d*"
              value={inputResNumBox[0]}
              onChange={e => onChangeResNumInput(e, 0)}
              onPaste={onPaste}
              variant={appearanceResNum.variant}
              minLength={MAX_LENGTH_RES_NUM_PER_INPUT[0]}
              maxLength={MAX_LENGTH_RES_NUM_PER_INPUT[0]}
            />
            {' - '}
            <Input
              required
              id="input-preapproval-nr-box-2"
              ref={refInputResNumInput2}
              type="text"
              placeholder="000"
              pattern="\d*"
              value={inputResNumBox[1]}
              onChange={e => onChangeResNumInput(e, 1)}
              onPaste={onPaste}
              variant={appearanceResNum.variant}
              minLength={MAX_LENGTH_RES_NUM_PER_INPUT[1]}
              maxLength={MAX_LENGTH_RES_NUM_PER_INPUT[1]}
            />
            {' - '}
            <Input
              required
              id="input-preapproval-nr-box-3"
              ref={refInputResNumInput3}
              type="text"
              placeholder="0000"
              pattern="\d*"
              value={inputResNumBox[2]}
              onChange={e => onChangeResNumInput(e, 2)}
              onPaste={onPaste}
              variant={appearanceResNum.variant}
              minLength={MAX_LENGTH_RES_NUM_PER_INPUT[2]}
              maxLength={MAX_LENGTH_RES_NUM_PER_INPUT[2]}
              errorMessage="Please double check the 10 digit Preapproval number on the letter mailed to you."
            />
          </div>
        </div>
      </div>

      <div className={styles.fieldset}>
        <h4 className={`heading-gradient-secondary ${styles.number}`}>2</h4>
        <div>
          <label htmlFor="input-zip-code">
            <h5 className={styles.label}>
              Enter Your Zip Code
              {appearanceZip.variant === 'success' && (
                <CheckmarkCircleSVGlement />
              )}
            </h5>
          </label>
          <div className={styles.zipCodeInput}>
            <Input
              required
              id="input-zip-code"
              ref={refInputZip}
              type="text"
              placeholder="00000"
              pattern="\d*"
              value={inputZip}
              variant={appearanceZip.variant}
              onChange={onChangeZip}
              minLength={MAX_LENGTH_ZIP}
              maxLength={MAX_LENGTH_ZIP}
              errorMessage="Please enter the zip code on the letter mailed to you."
            />
          </div>
        </div>
      </div>

      <div className={styles.fieldset}>
        <h4 className={`heading-gradient-secondary ${styles.number}`}>3</h4>
        <div>
          <label htmlFor="input-phone-number">
            <h5 className={styles.label}>Enter Your Phone Number</h5>
          </label>
          <div>
            <Input
              required
              id="input-phone-number"
              ref={refInputPhone}
              type="text"
              inputMode="text"
              placeholder="(000) 000-0000"
              pattern="\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}"
              value={inputPhone}
              onChange={onChangePhone}
              minLength={MAX_LENGTH_PHONE + 4}
              maxLength={MAX_LENGTH_PHONE + 4}
              errorMessage="Please provide a valid 10-digit phone number"
            />
          </div>
        </div>
      </div>

      <div className={styles.fieldset}>
        <h4 className={`heading-gradient-secondary ${styles.number}`}>4</h4>
        <div>
          <label htmlFor="input-email">
            <h5 className={styles.label}>Enter Your Email Address</h5>
          </label>
          <div className={styles.emailInput}>
            <Input
              required
              id="input-email"
              ref={refInputEmail}
              type="email"
              pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
              onChange={onChangeEmail}
              onBlur={onBlurEmail}
              errorMessage="Please provide a valid email addres"
              placeholder="Your email address"
            />
          </div>
        </div>
      </div>

      <div>
        <RichText data={data?.disclaimer} className={styles.disclaimer} />
        {button}
      </div>
    </form>
  );
}

FormPreloadFromPreapproval10.propTypes = {
  data: shape(),
  creative: string,
  dataset: string,
  source: string,
  pageContext: shape(),
};
