import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/elements/Buttons/Button';

import styles from './styles.module.css';

export const Tip = ({ children }) => {
  const refParagraphTip = useRef();

  const [heightParagraphTip, setHeightParagraphTip] = useState(0);

  useEffect(() => {
    updateHeightDropdownTip();
  }, [children, refParagraphTip]);

  useEffect(() => {
    window.addEventListener('resize', updateHeightDropdownTip);

    return () => window.removeEventListener('resize', updateHeightDropdownTip);
  }, [refParagraphTip]);

  const updateHeightDropdownTip = () => {
    setHeightParagraphTip(
      refParagraphTip?.current?.getBoundingClientRect()?.height || 0
    );
  };

  return (
    <div
      className={styles.wrapper}
      style={{ '--height-paragraph': heightParagraphTip }}
    >
      <Button disabled variant="xxs" theme="primary">
        ?
      </Button>
      <div className={styles.dropdownHoverArea}>
        <div className={styles.dropdownWhiteBox}>
          <p
            ref={refParagraphTip}
            dangerouslySetInnerHTML={{ __html: children }}
          />
        </div>
      </div>
    </div>
  );
};

Tip.propTypes = {
  children: PropTypes.node,
};
