export const formatPreApprovalNumber = str => {
  const cleaned = `${str}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3,4})$/);

  if (!match) {
    return str;
  }

  return match[1] + ' - ' + match[2] + ' - ' + match[3];
};
